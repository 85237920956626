export const IS_USER_LOGGEDIN = 'isUserLoggedIn';
export const USER_TOKEN = 'token';
export const USER_ID = 'user_id';
export const USER_NAME = 'name';
export const USER_EMAIL = 'email';
export const USER_AVATAR = 'avatar';
export const IS_SELLER = 'isSeller';
export class UserModel {
  [IS_USER_LOGGEDIN] = false;

  [USER_TOKEN] = '';

  [USER_ID] = '';

  [USER_NAME];

  [USER_EMAIL];

  [USER_AVATAR];

  [IS_SELLER];
}
export class User {
  _id;

  username;

  avatar;

  following;

  name;

  token;
}

export class userSettingData {
  emailAddr;

  phone_number;

  new_password;

  password;

  is_email_verified;
}
export class Address {
  _id;

  id;

  first_name = '';

  last_name = '';

  street_1 = '';

  street_2 = '';

  city = '';

  state = '';

  country = '';

  zip = '';

  phone = '';

  // ship_to?: boolean;
  // ship_from?: boolean;
  // legal?: boolean;
  flags = new AddressFlags();
}
export class AddressFlags {
  legal = false;

  ship_from = false;

  ship_to = false;
}
export class SellerProfile {
  _id;

  id;

  first_name = '';

  last_name = '';

  mobile = '';

  dob = new DOB();
  // address?: Address = new Address();
}
export class DOB {
  day;

  month;

  year;
}
