import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
//import "../assets/scss/index.scss";

import '../../assets/scss/index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AuthService from '../../../store/shared/services/authServices';
import {
  EXTERNAL_LINK_NAVIGATION,
  BUTTON_NAVIGATION
} from '../../../store/shared/models/misc';

const HeadMenuItem = ({ items = [], item, onClick, level, isMobile }) => {
  const [isActive, toggleIsActive] = useState(false);

  const onMouseEnterHandler = () => {
    if (!isMobile && level === 1) {
      toggleIsActive(true);
    }
  };

  const onMouseLeaveHandler = () => {
    if (!isMobile && level === 1) {
      toggleIsActive(false);
    }
  };

  const menuItems = items.map((item, index) => (
    <HeadMenuItem
      key={index}
      item={item}
      onClick={onClick}
      items={item.children && item.children}
      level={level + 1}
      isMobile={isMobile}
    />
  ));
  const hasItems = items.length > 0;

  return (
    <li
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onMouseUp={onMouseLeaveHandler}
      className={
        (level === 2 ? 'column is-3 col-sm' : '') +
        (isActive ? ' is-active' : '') +
        (hasItems ? ' has-items' : '')
      }
    >
      <div className='cat-parent'>
        {/* {item.type === EXTERNAL_LINK_NAVIGATION ? (
          <a href={item.to}>{item.name}</a>
        ) : (
          <NavLink
            activeClassName='is-active'
            className={hasItems ? 'has-items' : ''}
            to={item.to ? item.to : '/products/' + item._id}
            onClick={onClick}
          >
            {item.name}
          </NavLink>
        )} */}
        {item.type === EXTERNAL_LINK_NAVIGATION ? (
          <a href={item.to}>{item.name}</a>
        ) : item.type === BUTTON_NAVIGATION ? (
          <button
            className='btn btn-link '
            // activeClassName='is-active'
            // className={hasItems ? 'has-items' : ''}
            onClick={() => {
              item.callback();
              onClick();
            }}
          >
            {item.name}
          </button>
        ) : (
          <NavLink
            activeClassName='is-active'
            className={hasItems ? 'has-items' : ''}
            to={item.to ? item.to : '/products/' + item._id}
            onClick={onClick}
          >
            {item.name}
          </NavLink>
        )}
        {hasItems && isMobile && (
          <span onClick={() => toggleIsActive(!isActive)}>
            <FontAwesomeIcon
              icon={isActive ? 'chevron-down' : 'chevron-right'}
            />
          </span>
        )}
      </div>
      {hasItems && (
        <ul
          className={
            (level === 1 ? ' columns is-gapless is-multiline' : '') +
            ' nav-level-' +
            level
          }
        >
          {menuItems}
        </ul>
      )}
    </li>
  );
};

class MobileHeadMenu extends React.PureComponent {
  render() {
    const {
      data,
      isMobile,
      onClick,
      mobileMenuIsActive = false,
      direction = 'left'
    } = this.props;

    const items = data.map((item, index) => (
      <HeadMenuItem
        key={index}
        item={item}
        onClick={onClick}
        items={item.children && item.children}
        level={1}
        isMobile={isMobile}
      />
    ));

    return (
      <div>
        <div
          className={mobileMenuIsActive ? 'dark-overflow' : ''}
          onClick={onClick}
        />
        <div
          className={`mobile-nav d-lg-none  ${
            mobileMenuIsActive ? 'mobile-nav-open' : ''
          } ${direction}`}
        >
          <ul className='nav-level-0'>
            {items}
            {/* {sign()} */}
          </ul>
        </div>
      </div>
    );
  }
}

export default MobileHeadMenu;
