import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import loadable from '@loadable/component';
import Loading from '../../../theme/components/loading';

const AsyncSwapsContainer = () =>
	loadable(() =>
        import(
            /* webpackChunkName: "swapsListContainer" */ '../../../theme/containers/swaps'
        ), {
        fallback: (() => <Loading />)(),
    });
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AsyncSwapsContainer())
);
