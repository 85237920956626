import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProductUploadUrl } from '../../../store/shared/services/linkCreatorService';
import FreshChat from '../FreshChat';
import ClearSales from '../ClearSales';
import PaymentMethodInfo from '../PaymentMethodsInfo';
import GetTheApp from './getTheApp';

class Footer extends React.PureComponent {
  render() {
    return (
      <footer>
        <div className="wrapper footer pt-4 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 col-6">
                <ul className="footerList">
                  <li className="text-nowrap">
                    <b>Get the App</b>
                  </li>
                  <GetTheApp />
                </ul>
              </div>
              <div className="col-sm-3 col-6">
                <ul className="footerList">
                  <li className="text-nowrap">
                    <b>Follow Us</b>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/PCswaps"
                    >
                      <FontAwesomeIcon
                        className="socialMediaIcons"
                        icon={['fab', 'facebook-square']}
                      />
                      Facebook
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/pcswaps/"
                    >
                      <FontAwesomeIcon
                        className="socialMediaIcons"
                        icon={['fab', 'instagram']}
                      />
                      Instagram
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/PCswaps"
                    >
                      <FontAwesomeIcon
                        className="socialMediaIcons"
                        icon={['fab', 'twitter']}
                      />
                      Twitter
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCWysrVz4oysH_kdwL0i_qVQ"
                    >
                      <FontAwesomeIcon
                        className="socialMediaIcons"
                        icon={['fab', 'youtube']}
                      />
                      YouTube
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://discord.gg/zEEeX9W"
                    >
                      <FontAwesomeIcon
                        className="socialMediaIcons"
                        icon={['fab', 'discord']}
                      />
                      Discord
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-3 col-6">
                <ul className="footerList ">
                  <li>
                    <b>Support</b>
                  </li>
                  <li className="text-nowrap">
                    <NavLink to={getProductUploadUrl()}>
                      Sell on PC swaps
                    </NavLink>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/selling-2/"
                    >
                      Selling Basics
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/category/shipping/"
                    >
                      Shipping
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/buying-2/"
                    >
                      Buying Basics
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/what-is-buyer-protection/"
                    >
                      Buyer Protection
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/is-my-credit-card-or-debit-card-secure-2/"
                    >
                      Payment Security
                    </a>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/refund-and-return-policy/"
                    >
                      Refund Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-3 col-6">
                <ul className="footerList">
                  <li className="text-nowrap">
                    <b>PC swaps</b>
                  </li>

                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/about-us/"
                    >
                      About Us
                    </a>
                  </li>

                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/"
                    >
                      Help Center
                    </a>
                  </li>

                  <li className="text-nowrap">
                    <NavLink to="/howitworks"> How it Works</NavLink>
                  </li>
                  <li className="text-nowrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://blog.pcswaps.com/category/contact-us/"
                    >
                      Contact us
                    </a>
                  </li>
                  <li>
                    <NavLink to="/terms-of-use"> Terms</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy"> Privacy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sitemap">Sitemap</NavLink>
                  </li>
                </ul>
              </div>

              {/* <div className='col-sm-3'>
                <address>
                  <strong>Twitter, Inc.</strong>
                  <br />
                  1355 Market St, Suite 900
                  <br />
                  San Francisco, CA 94103
                  <br />
                  <abbr title='Phone'>P:</abbr> (123) 456-7890
                </address>
              </div> */}
            </div>
            <div
              className="trustpilot-widget text-center mb-4"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="5fae1e569a033e000108132c"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://www.trustpilot.com/review/pcswaps.com"
                target="_blank"
                rel="noreferrer"
              >
                Trustpilot
              </a>
            </div>
            <div className="row justify-content-center text-muted">
              <>Copyright &copy;2019 - {new Date().getFullYear()} Swaps LLC</>
              <PaymentMethodInfo />
            </div>
          </div>
        </div>
        {/* <Zendesk /> */}
        <FreshChat />
        <ClearSales />
      </footer>
    );
  }
}
export default Footer;
