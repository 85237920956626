import React from 'react';
import loadable from '@loadable/component';
import Loading from '../../../theme/components/loading';
const AsyncSearchContainer = () =>
	loadable(() =>
        import(
            /* webpackChunkName: "SearchContainer" */ '../../../theme/containers/search'
        ), {
        fallback: (() => <Loading />)(),
    });
export default AsyncSearchContainer();
