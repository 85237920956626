export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_RECEIVE = 'PRODUCT_RECEIVE';
// export const PRODUCT_FAILURE = 'PRODUCT_FAILURE'

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_RECEIVE = 'PRODUCTS_RECEIVE';
// export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE'

export const MORE_PRODUCTS_REQUEST = 'MORE_PRODUCTS_REQUEST';
export const MORE_PRODUCTS_RECEIVE = 'MORE_PRODUCTS_RECEIVE';
// export const MORE_PRODUCTS_FAILURE = 'MORE_PRODUCTS_FAILURE'

export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_RECEIVE = 'PAGE_RECEIVE';
// export const PAGE_FAILURE = 'PAGE_FAILURE'

export const CART_REQUEST = 'CART_REQUEST';
export const CART_RECEIVE = 'CART_RECEIVE';
// export const CART_FAILURE = 'CART_FAILURE'

export const CART_ITEM_ADD_REQUEST = 'CART_ITEM_ADD_REQUEST';
// export const CART_ITEM_ADD_RECEIVE = 'CART_ITEM_ADD_RECEIVE'
// export const CART_ITEM_ADD_FAILURE = 'CART_ITEM_ADD_FAILURE'

export const CART_ITEM_DELETE_REQUEST = 'CART_ITEM_DELETE_REQUEST';
// export const CART_ITEM_DELETE_RECEIVE = 'CART_ITEM_DELETE_RECEIVE'
// export const CART_ITEM_DELETE_FAILURE = 'CART_ITEM_DELETE_FAILURE'

export const CART_ITEM_UPDATE_REQUEST = 'CART_ITEM_UPDATE_REQUEST';
// export const CART_ITEM_UPDATE_RECEIVE = 'CART_ITEM_UPDATE_RECEIVE'
// export const CART_ITEM_UPDATE_FAILURE = 'CART_ITEM_UPDATE_FAILURE'

export const SHIPPING_METHODS_REQUEST = 'SHIPPING_METHODS_REQUEST';
export const SHIPPING_METHODS_RECEIVE = 'SHIPPING_METHODS_RECEIVE';
// export const SHIPPING_METHODS_FAILURE = 'SHIPPING_METHODS_FAILURE'

export const PAYMENT_METHODS_REQUEST = 'PAYMENT_METHODS_REQUEST';
export const PAYMENT_METHODS_RECEIVE = 'PAYMENT_METHODS_RECEIVE';
// export const PAYMENT_METHODS_FAILURE = 'PAYMENT_METHODS_FAILURE'

export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_RECEIVE = 'CHECKOUT_RECEIVE';
// export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'

export const SITEMAP_REQUEST = 'SITEMAP_REQUEST';
export const SITEMAP_RECEIVE = 'SITEMAP_RECEIVE';
// export const SITEMAP_FAILURE = 'SITEMAP_FAILURE'

export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN';
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER';

export const LOCATION_CHANGED = 'LOCATION_CHANGED';

export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALs = 'CLOSE_ALL_MODALS';

// User Actions
export const USER_LOGIN = 'USER_LOGIN';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_REGISTER = 'USER_REGISTER';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';
export const AUTHENTICATE = 'AUTHENTICATE';
export const SET_CURRENT_TOKENS = 'SET_CURRENT_TOKENS';
