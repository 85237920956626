import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Login from './login';
import Register from './register';
import Tabs from '../tabs/tabs';

class LoginRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
    };
    this.defaultActiveTab =
      this.props.location.state &&
      this.props.location.state.activeTab === 'register'
        ? 1
        : 0;
  }

  componentDidMount() {
    let activeTab = 'login';
    if (this.props.activeTab) {
      activeTab = 'register';
    } else if (this.props.location && this.props.location.state) {
      activeTab = this.props.location.state.activeTab || 'login';
    }
    this.setState({ activeTab });
  }

  onTabChange = (activateTab) => {
    if (this.state.activeTab !== activateTab) {
      this.setState({ activeTab: activateTab });
    }
  };

  onSuccess = (user) => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  render() {
    const defaultActiveTab =
      this.props.location.state &&
      this.props.location.state.activeTab === 'register'
        ? 1
        : 0;
    return (
      <>
        <div className="container">
          <Tabs defaultActiveTab={defaultActiveTab}>
            <div label="Login">
              <Login
                loginUser={this.props.loginUser}
                onSuccess={this.onSuccess}
              />
            </div>
            <div label="Register">
              <Register
                registerUser={this.props.registerUser}
                onSuccess={this.onSuccess}
              />
            </div>
          </Tabs>
        </div>
      </>
    );
  }
}

export default withRouter(LoginRegister);
