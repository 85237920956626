import React, { Fragment, useState } from 'react';
const OfferContainer = ({ product, closeModal }) => {
  const [offerAmount, setOfferPrice] = useState('');
  if (!product) return '';
  return (
    <Fragment>
      <h4>{product.name}</h4>
      <div className='form-group'>
        <label htmlFor='offer-box' className=''>
          Offer Amount
        </label>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <div className='input-group-text'>USD</div>
          </div>
          <input
            type='text'
            id='offer-box'
            className='form-control col-4'
            value={offerAmount}
            placeholder={` ${product.price}`}
            onChange={e => setOfferPrice(e.target.value)}
          />
          <div className='input-group-append'>
            <div className='input-group-text'>$</div>
          </div>
        </div>
      </div>
      <p>
        <small>
          Asking price: <b>${product.price}</b>
        </small>
      </p>

      <button
        className='btn btn-primary'
        onClick={() => closeModal({ offerAmount: offerAmount })}
        disabled={offerAmount === ''}
      >
        Review Offer
      </button>
    </Fragment>
  );
};
export default OfferContainer;
