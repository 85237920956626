import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODALs } from '../actionTypes';
import Modal from '../../models/modal';
let id = 0;
/* --- ACTIONS --- */
export const openModal = payload => {
  return {
    type: OPEN_MODAL,
    payload: {
      id: id++,
      ...payload
    }
  };
};
export const closeModal = id => {
  return {
    type: CLOSE_MODAL,
    payload: {
      id
    }
  };
};
export const closeAllModals = () => {
  return {
    type: CLOSE_ALL_MODALs
  };
};
