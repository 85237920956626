import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import * as userAction from '../redux/actions/userAction';
import Loading from '../../../theme/components/loading';

const AsyncloginRegisterContainer = () =>
	loadable(() =>
        import(
    /* webpackChunkName: "loginRegister" */ '../../../theme/containers/loginRegister'
        ), {
        fallback: (() => <Loading />)(),
    });
const mapStateToProps = (state) => ({
	user: { ...state.user },
});
export default withRouter(
	connect(mapStateToProps, userAction)(AsyncloginRegisterContainer())
);
