import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { SITE_TAGLINE } from '../../store/shared/models/misc';

const MetaTags = ({
  ogId,
  title,
  description,
  canonicalUrl,
  imageUrl,
  ogType,
  ogTitle,
  ogLocale,
  ogSiteName,
  ogDescription,
  productAvailability,
  productCondition,
  productPrice,
  productCurrency,
  productId,
  productBrand,
  jsonld,
  themeColor,
}) => {
  const metaArray = [];
  const linkArray = [];
  const scriptArray = [];
  if (productBrand && productBrand.length > 0) {
    metaArray.push({
      name: 'product:brand',
      content: productBrand,
    });
  }

  if (productAvailability && productAvailability.length > 0) {
    metaArray.push({
      name: 'product:availability',
      content:
        productAvailability === 'sold'
          ? 'out of stock'
          : productAvailability === 'removed'
          ? 'discontinued'
          : 'in stock',
    });
  }
  if (description && description.length > 0) {
    metaArray.push({
      name: 'description',
      content: description,
    });
  }
  if (productCondition && productCondition.length > 0) {
    metaArray.push({
      name: 'product:condition',
      content: productCondition,
    });
  }
  if (productPrice && productPrice.length > 0) {
    metaArray.push({
      name: 'product:price:amount',
      content: productPrice,
    });
  }
  if (productCurrency && productCurrency.length > 0) {
    metaArray.push({
      name: 'product:price:currency',
      content: productCurrency,
    });
  }
  if (productId && productId.length > 0) {
    metaArray.push({
      name: 'product:retailer_item_id',
      content: productId,
    });
  }
  if (ogLocale && ogLocale.length > 0) {
    metaArray.push({
      name: 'og:locale',
      content: ogLocale,
    });
  }
  if (ogSiteName && ogSiteName.length > 0) {
    metaArray.push({
      name: 'og:site_name',
      content: ogSiteName,
    });
  }
  if (ogId && ogId.length > 0) {
    metaArray.push({
      name: 'og:id',
      content: ogId,
    });
  }
  if (canonicalUrl && canonicalUrl.length > 0) {
    linkArray.push({
      rel: 'canonical',
      href: canonicalUrl,
    });

    metaArray.push({
      property: 'og:url',
      content: canonicalUrl,
    });
  }

  if (imageUrl && imageUrl.length > 0) {
    metaArray.push({
      property: 'og:image',
      content: imageUrl,
    });

    linkArray.push({
      rel: 'image_src',
      href: imageUrl,
    });
  }

  if (ogType && ogType.length > 0) {
    metaArray.push({
      property: 'og:type',
      content: ogType,
    });
  }

  if (ogTitle && ogTitle.length > 0) {
    metaArray.push({
      property: 'og:title',
      content: `${ogTitle.slice(0, 150)}`,
    });
  }

  if (ogDescription && ogDescription.length > 0) {
    metaArray.push({
      property: 'og:description',
      content: ogDescription,
    });
  }
  if (themeColor && themeColor.length > 0) {
    metaArray.push({
      property: 'theme-color',
      content: themeColor,
    });
  }
  const scriptJSONLD =
    jsonld && jsonld.length > 0 ? (
      <script type="application/ld+json">{jsonld}</script>
    ) : null;
  if (jsonld && jsonld.length > 0) {
    scriptArray.push({
      type: 'application/ld+json',
      innerHTML: jsonld,
    });
  }
  return (
    <Helmet
      title={`${title.slice(0, 150)}`}
      meta={metaArray}
      link={linkArray}
      script={scriptArray}
      htmlAttributes={{ lang: 'en' }}
    />
  );
};

MetaTags.propTypes = {
  ogId: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  ogType: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  jsonld: PropTypes.string,
  productAvailability: PropTypes.string,
  productCondition: PropTypes.string,
  productPrice: PropTypes.number,
  productCurrency: PropTypes.string,
  productId: PropTypes.string,
  productBrand: PropTypes.string,
};
MetaTags.defaultProps = {
  ogId: null,
  title: 'PC Swaps',
  description: null,
  canonicalUrl: null,
  imageUrl: null,
  ogType: null,
  ogTitle: null,
  ogDescription: null,
  jsonld: null,
};
export default MetaTags;
