import queryString from 'query-string';
import {
  SWAP_ACTION_SHIPMENT,
  SWAP_ACTION_RECEIVED,
  SWAP_ACTION_SHIPPED,
  SWAP_ACTION_COMPLETED,
} from '../models/swaps';
import apiService from './apiService';

// export const API_ENDPOINT_LOGIN = 'user/login';
export const API_ENDPOINT_LOGIN = 'auth/login';
export const API_ENDPOINT_SILENT_LOGIN = 'auth/refresh';
export const API_ENDPOINT_AUTH_LOGOUT = 'auth/logout';
export const API_ENDPOINT_AUTH_SESSION = 'auth/session';

export const API_ENDPOINT_REGISTER = 'user/register';
export const API_ENDPOINT_USERS = 'users';
export const API_ENDPOINT_LOGOUT = 'logout';
export const API_ENDPOINT_PRODUCTS = 'items';
export const API_ENDPOINT_PRODUCT = 'items';
export const API_ENDPOINT_FACET_ITEMS = 'facet_items';
export const API_ENDPOINT_PRODUCT_DETAIL_FOR_EDIT = 'product-details-edit';
export const API_ENDPOINT_SAVE_PRODUCT = 'save-product';
export const API_ENDPOINT_CATEGORIES_LIST = 'categories-list';
export const API_ENDPOINT_CATEGORY_ATTRIBUTES = 'category-attributes';
export const API_ENDPOINT_UPLOAD_IMAGES = 'image';
export const API_ENDPOINT_UPLOAD_AVATAR = 'users/avatar';
export const API_ENDPOINT_SAVE_ADDRESS = 'save-address';
export const API_ENDPOINT_ADDRESS_LIST = 'addresses';
export const API_ENDPOINT_SELLER_ACCOUNT = 'seller-account';
// export const API_ENDPOINT_SELLER_PROFILE = 'seller-account/profile';
export const API_ENDPOINT_CREATE_SELLER = 'seller';
export const API_ENDPOINT_SWAPS = 'swaps';
export const API_ENDPOINT_SHIPPING = 'shipping';
export const API_ENDPOINT_POST_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_GET_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_GET_PAYMENT_METHODS = 'payment-methods';
export const API_ENDPOINT_DELETE_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_CATEGORIES = 'categories';
export const API_ENDPOINT_FORGOTPASSWORD = 'forgot-password';
export const API_ENDPOINT_USERS_SETTING = 'users/settings';
export const API_ENDPOINT_PASSWORD_RESET = 'password/reset';
export const API_ENDPOINT_EMAIL_TO_RESET_PASSWORD = 'password/email';
export const API_ENDPOINT_EMAIL_TO_VERIFY = 'email/verify';
export const API_ENDPOINT_GET_SELLER_DETAILS = 'sellers';

export const itemsApiEndPoint = (itemId = '') =>
  `${API_ENDPOINT_PRODUCT}${itemId ? `/${itemId}` : ''}`;
export const shippingQuoteForItem = (itemId, shipToAddressID) =>
  `items/${itemId}/shipping?ship_to_address_id=${shipToAddressID}`;
export const swapOfferUpdateEndpoint = (swapId, offerId = '', type = '') => {
  if (
    type !== SWAP_ACTION_SHIPMENT &&
    type !== SWAP_ACTION_RECEIVED &&
    type !== SWAP_ACTION_SHIPPED &&
    type !== SWAP_ACTION_COMPLETED
  ) {
    return `${swapsEndpoint(swapId)}/offers${offerId ? `/${offerId}` : ''}${
      type ? `/${type}` : ''
    }`;
  }
  return `${swapsEndpoint(swapId)}/${type}`;
  // return `swaps/${swapId}/offers${offerId ? '/' + offerId : ''}${type ? '/' + type : ''}`;
};
export const swapUpdateEndpoint = (swapId, state) =>
  `${swapsEndpoint(swapId)}/${state}`;
export const swapsEndpoint = (swapId, type = '') =>
  `swaps${swapId ? `/${swapId}` : ''}${type ? `/${type}` : ''}`;
export const swapFeedbackEndpoint = (swapId) =>
  `${swapsEndpoint(swapId)}/feedback`;
export const paymentMethodEndpoint = (methodId = '') =>
  `payment-methods${methodId ? `/${methodId}` : ''}`;
export const transactionsEndpoint = (type = []) =>
  `transactions?${queryString.stringify({ type }, { arrayFormat: 'bracket' })}`;
export const sellerAccountEndpoint = (type = '') => {
  const userId = apiService.getUser().user_id;
  return `${API_ENDPOINT_USERS}/${userId}/${API_ENDPOINT_SELLER_ACCOUNT}${
    type ? `/${type}` : ''
  }`;
};
export const sellerAcountProfileEndpoint = () =>
  `${sellerAccountEndpoint()}/profile`;

export const itemsListEndpoint = (username) =>
  `${API_ENDPOINT_USERS}/${username}/items`;
export const itemsFavoritesEndpoint = (itemId) =>
  `${API_ENDPOINT_PRODUCT}/${itemId}/favorite`;

export const userFeedbackEnpoint = (username) =>
  `${API_ENDPOINT_USERS}/${username}/feedback`;

export const transactionsApiParams = (type = []) => ({
  endpoint: 'transactions',
  params: { type },
});
export const swapsListApiParams = (type, scope) => ({
  endpoint: API_ENDPOINT_SWAPS,
  params: { type, scope },
});
export const itemSwapsListApiParams = (itemId) => ({
  endpoint: `${API_ENDPOINT_SWAPS}/item/${itemId}`,
  params: {},
});
export const itemsApiParams = (params = {}) => ({
  endpoint: API_ENDPOINT_FACET_ITEMS,
  params: { ...params },
});
export const userFavoritesApiParams = (username) => ({
  endpoint: `${API_ENDPOINT_USERS}/${username}/favorites`,
  params: {},
});
// export const transactionsEndpoint = ()
