/* eslint-disable import/no-cycle */
import axios from 'axios';
import { propTypes } from 'react-recaptcha';
import {
  showErrorToast,
  showSuccessToast,
} from '../redux/actions/toastsAction';
import { fetchAccessToken, logoutUser } from '../redux/actions/userAction';

import {
  API_ENDPOINT_UPLOAD_IMAGES,
  API_ENDPOINT_UPLOAD_AVATAR,
} from './apiEndpoints';
import { isServer } from '../redux/store';

const Baseurl = {
  url: process.env.RAZZLE_API_URL,
};
let user;
let refreshToken;
let storeDispatch;
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
let reduxStore;
function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function onAccessTokenFailed() {
  subscribers.filter((callback) => callback(false));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}
const handleApiError = (err, showAlert, reject) => {
  if (showAlert && !isServer) {
    let msg = '';
    if (err.response && err.response.status && err.response.status === 401) {
      msg = 'Your session has expired. Please login again to continue';
    } else {
      msg = err.message
        ? err.message
        : 'Something went wrong, please try again later';
    }

    storeDispatch(showErrorToast(msg));
  }
  if (reject) {
    reject(err);
  }
};

const handleApiSuccess = (resp, resolve) => {
  if (resp.message && resp.message !== 'success') {
    storeDispatch(showSuccessToast(resp.message));
  }
  if (resolve) {
    resolve(resp);
  }
};
const instance = axios.create();
const apiService = {
  updateUser: (userData = {}) => {
    user = { ...userData };
  },
  getReduxState() {
    return reduxStore ? reduxStore.getState() : null;
  },
  getAccessToken() {
    const state = this.getReduxState();
    if (!state) {
      return null;
    }
    if (!state.user || !state.user.access_token) {
      return null;
    }
    return state.user.access_token;
  },
  getRefreshToken() {
    const state = this.getReduxState();
    if (!state || !state.user || !state.user.refresh_token) {
      return null;
    }
    return state.user.refresh_token;
  },
  getUser: () => user,
  request(method = 'POST', endpoint, data = {}, showErrorAlert = true) {
    const access_token = this.getAccessToken();

    const requestParams = { url: Baseurl.url + endpoint, method };
    // console.log(FormData);
    const isFormData = false;
    // let isFormData = FormData && data instanceof FormData ? true : false;
    const headers = {
      'Content-Type': !isFormData
        ? 'application/json;charset=UTF-8'
        : 'multipart/form-data',
    };

    if (access_token) {
      Object.assign(headers, { Authorization: `Bearer ${access_token}` });
    }

    Object.assign(requestParams, { headers: { ...headers } });
    const params = isFormData ? data : JSON.stringify(data);

    if (method === 'GET') {
      Object.assign(requestParams, {
        params: data,
      });
    } else if (data) {
      Object.assign(requestParams, {
        data: params,
      });
    }
    return new Promise((resolve, reject) => {
      instance(requestParams).then(
        (resp) => {
          if (resp.data.status === true) {
            handleApiSuccess(resp.data, resolve);
          } else {
            handleApiError(resp.data, showErrorAlert, reject);
          }
        },
        (err) => handleApiError(err, showErrorAlert, reject)
      );
    });
  },
  uploadImage(data, showErrorAlert = false) {
    return new Promise((resolve, reject) => {
      instance
        .post(`${Baseurl.url}${API_ENDPOINT_UPLOAD_IMAGES}`, data, {
          headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data;`,
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.status === true) {
            handleApiSuccess(resp.data.data.images, resolve);
          } else {
            handleApiError(resp.data, showErrorAlert, reject);
          }
        })
        .catch((err) => {});
    });
  },
  uploadAvatar(data, showErrorAlert = false) {
    return new Promise((resolve, reject) => {
      const access_token = this.getAccessToken();
      instance
        .post(`${Baseurl.url}${API_ENDPOINT_UPLOAD_AVATAR}`, data, {
          headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((resp) => {
          if (resp.data.status) {
            handleApiSuccess(resp.data.data.avatar, resolve);
          } else {
            handleApiError(resp.data, showErrorAlert, reject);
          }
        })
        .catch((err) => {});
    });
  },
};
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { config, response } = error;
    const originalRequest = config;
    // const refreshTokenCookies = apiService.getRefreshToken();
    refreshToken = apiService.getRefreshToken();

    if (response && response.status === 401 && refreshToken) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        storeDispatch(fetchAccessToken(refreshToken))
          .then((access_token) => {
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(access_token);
          })
          .catch((err) => {
            isAlreadyFetchingAccessToken = false;
            if (!isServer) {
              storeDispatch(logoutUser());
            }
            onAccessTokenFailed();
          });
      }

      const retryOriginalRequest = new Promise((resolve, reject) => {
        addSubscriber((access_token) => {
          if (!access_token) {
            reject('unauthorized access1');
          } else {
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            resolve(axios(originalRequest));
          }
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export const apiServiceMiddleware = (store) => {
  reduxStore = store;
  return (next) =>{
    storeDispatch = next;
    return (action) => next(action) 
  }
};
export default apiService;
