import { SORTING } from './misc';

const conditions = ['New', 'Used', 'For Parts'];

export const CONDITION = {
  name: 'condition',
  slug: 'condition',
  values: [
    { name: 'New', checked: false },
    { name: 'Used', checked: false },
    { name: 'For Parts', checked: false },
  ],
};

export class ProductFilterModel {
  attributes = {};

  categoryId;

  limit;

  onSale;

  priceFrom = 0;

  priceTo = 0;

  search = '';

  sort = SORTING.NEWEST;

  fields = '';

  state = '';
}

export class CategoryDetails {
  name;

  full_name;

  slug;

  image;

  parent_id;

  enabled;

  id;

  url;

  path;

  description;
}
export class CategoryAttributes {
  name;

  slug;

  options = [];
}
export const CONDITION_ATTRIBUTE = {
  name: 'Condition',
  slug: 'condition',
  options: conditions,
  is_required: true,
};
