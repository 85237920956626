import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';
import { configureStore } from '../store/shared/redux/store';
import App from '../store/shared/App';

const store = configureStore(window.__PRELOADED_STATE__);
const root = document.getElementById('root');

function render(Root) {
  loadableReady(() => {
    hydrate(
      <Provider store={store}>
        <Router>
          <Root preloadedData={window.__PRELOADED_DATA__} />
        </Router>
      </Provider>,
      root
    );
  });
}
render(App);
if (module.hot) {
  module.hot.accept('../store/shared/App', () => {
    const NewApp = require('../store/shared/App').default;
    render(NewApp);
  });
}
