/* eslint-disable import/no-cycle */
import Cookies from 'js-cookie';
import Axios from 'axios';
import Analytics from '../../services/analytics';
import {
  SET_CURRENT_USER,
  AUTHENTICATE,
  REMOVE_CURRENT_USER,
  UPDATE_ACCESS_TOKEN,
  SET_CURRENT_TOKENS,
  SET_USER_AVATAR,
} from '../actionTypes';

import apiService from '../../services/apiService';
import {
  API_ENDPOINT_AUTH_LOGOUT,
  API_ENDPOINT_AUTH_SESSION,
  API_ENDPOINT_LOGIN,
  API_ENDPOINT_REGISTER,
  API_ENDPOINT_SILENT_LOGIN,
} from '../../services/apiEndpoints';
import { IS_USER_LOGGEDIN } from '../../models/user';
import { isServer } from '../store';

export const updateAccessToken = (token) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: UPDATE_ACCESS_TOKEN,
      payload: { ...token },
    });
    Cookies.set('access_token', token, { expires: 365 });
  });

export const setCurrentTokens = (tokens) => (dispatch) =>
  new Promise((resolve) => {
    const { access_token, refresh_token } = tokens;

    dispatch({
      type: SET_CURRENT_TOKENS,
      payload: tokens,
    });
    if (!isServer) {
      Cookies.set('access_token', access_token, { expires: 365 });
      if (refresh_token) {
        Cookies.set('refresh_token', refresh_token, { expires: 365 });
      }
    }
  });

export const fetchAccessToken = (refresh_token) => (dispatch) =>
  new Promise((resolve, reject) => {
    apiService
      .request(
        'POST',
        API_ENDPOINT_SILENT_LOGIN,
        {
          token: refresh_token,
          grant_type: 'refresh_token',
        },
        false
      )
      .then((resp) => {
        dispatch(setCurrentTokens({ access_token: resp.data.token }));
        // dispatch(updateAccessToken(resp.data.token));
        // apiService.updateAccessToken(resp.data.token);
        resolve(resp.data.token);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const setCurrentUser = (user = {}) => (dispatch) =>
  new Promise((resolve) => {
    apiService.updateUser(user);
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        ...user,
      },
    });

    dispatch({
      type: AUTHENTICATE,
      payload: { [IS_USER_LOGGEDIN]: true },
    });
    resolve(user);
  });

export const loginUser = (creds) => (dispatch) =>
  new Promise((resolve, reject) => {
    apiService
      .request('POST', API_ENDPOINT_LOGIN, creds)
      .then((resp) => {
        apiService.updateUser(resp.data);
        // dispatch(setCurrentUser(resp.data));
        dispatch(setCurrentUser(resp.data));
        dispatch(setCurrentTokens(resp.meta.authorization));
        Analytics.onSignIn(resp.data);
        return resp;
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const registerUser = (creds) => (dispatch) =>
  new Promise((resolve, reject) => {
    apiService
      .request('POST', API_ENDPOINT_REGISTER, creds)
      .then((resp) => {
        dispatch(setCurrentUser(resp.data));
        dispatch(setCurrentTokens(resp.meta.authorization));
        resolve(resp);
        Analytics.onSignUp(resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const logoutUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    apiService
      .request('POST', API_ENDPOINT_AUTH_LOGOUT, null, false)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });

    dispatch({
      type: AUTHENTICATE,
      payload: { [IS_USER_LOGGEDIN]: false },
    });

    dispatch({
      type: REMOVE_CURRENT_USER,
      payload: {},
    });
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    Analytics.onSignOut();
    apiService.updateUser({});
    resolve({});
  });

export const userUpdate = (payload) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: { ...payload },
    });
    resolve(payload);
  });

export const setUserAvatar = (avatar) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({ type: SET_USER_AVATAR, payload: avatar });
    resolve(avatar);
  });

export const getUserFromSession = () => (dispatch) =>
  new Promise((resolve, reject) => {
    // refreshing access token
    apiService
      .request('GET', API_ENDPOINT_AUTH_SESSION, null, false)
      .then((res) => {
        dispatch(setCurrentUser({ ...res.data }));
        resolve({ ...res.data });
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

export const refreshSession = () => (dispatch) => {
  setInterval(() => {
    dispatch(getUserFromSession());
  }, 5 * 60 * 1000);
};

export const toggleDarkmode = () => {
  if (document.body.className === 'darkmode') {
    document.body.className = 'lightmode';
    Cookies.set('theme_mode', 'lightmode');
  } else {
    document.body.className = 'darkmode';
    Cookies.set('theme_mode', 'darkmode');
  }
};
