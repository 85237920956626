// import ReactDOM from 'react-dom';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faTimesCircle,
  faMinusCircle,
  faHeart as faFasHeart,
  faChevronRight,
  faChevronDown,
  faSearch,
  faSlidersH,
  faShieldAlt,
  faUserShield,
  faShippingFast,
  faMoneyBillWave,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faDiscord,
  faYoutube,
  faCanadianMapleLeaf,
} from '@fortawesome/free-brands-svg-icons';
import { faHeart as faFarHeart } from '@fortawesome/free-regular-svg-icons';

config.autoAddCss = false;
library.add(
  faCheckCircle,
  faTimesCircle,
  faMinusCircle,
  faFasHeart,
  faFarHeart,
  faChevronRight,
  faChevronDown,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faSearch,
  faSlidersH,
  faCanadianMapleLeaf,
  faDiscord,
  faYoutube,
  faShieldAlt,
  faUserShield,
  faShippingFast,
  faMoneyBillWave
);
