import {
  SHOW_SUCCESS_TOAST,
  SHOW_ERROR_TOAST,
  REMOVE_TOAST
} from '../actionTypes';

let id = 0;
export function showSuccessToast(message) {
  return {
    type: SHOW_SUCCESS_TOAST,
    payload: {
      message,
      id: id++
    }
  };
}
export function showErrorToast(message) {
  return {
    type: SHOW_ERROR_TOAST,
    payload: {
      message,
      id: id++
    }
  };
}
export function addToast(type, message) {
  return {
    type: type,
    payload: {
      message,
      id: id++
    }
  };
}
export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    payload: {
      id
    }
  };
}
