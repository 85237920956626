import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import loadable from '@loadable/component';
import Loading from '../../../theme/components/loading';
const AsyncCreateEditItemContainer = () =>
  loadable(() =>
    import(
      /* webpackChunkName: "itemForm" */ '../../../theme/containers/createEditItem'
    ), {
    fallback: (() => <Loading />)(),
  });
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AsyncCreateEditItemContainer())
);
