import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import Loading from '../../../theme/components/loading';

const AsyncIndexContainer = () =>
  loadable(() =>
    import(/* webpackChunkName: "home" */ '../../../theme/containers/index'), {
    fallback: (() => <Loading />)(),
  });
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsyncIndexContainer())
);
