import ReactGA from 'react-ga';

class Analytics {
  static onSignIn = async (userObject) => {
    const { user_id } = userObject;
    ReactGA.set({ user_id });
    ReactGA.event({
      category: 'User',
      action: 'Sign in',
    });
  };

  static onSignUp = async (userObject) => {
    ReactGA.event({
      category: 'User',
      action: 'Sign up',
    });
  };

  static onSignOut = async () => {
    ReactGA.event({
      category: 'User',
      action: 'Sign out',
    });
    ReactGA.set({ user_id: null });
  };

  static beginCheckout = async (item) => {
    ReactGA.event({
      category: 'User',
      action: 'Began checkout',
      label: `${item.name}`,
    });
  };

  static purchase = async ({ shipping, value, transaction_id, item }) => {
    ReactGA.event({
      category: 'User',
      action: 'Purchased an item',
      label: `Transaction ID: ${transaction_id}, Item: ${item.name}, Value: $${value}, Shipping: $${shipping.rate}`,
    });
  };

  static addShippingInfo = async (item) => {
    ReactGA.event({
      category: 'User',
      action: 'Added Shipping Info',
      label: `${JSON.stringify(item)}`,
    });
  };

  static addPaymentInfo = async (item) => {
    ReactGA.event({
      category: 'User',
      action: 'Added Payment Info',
      label: `${JSON.stringify(item)}`,
    });
  };
}

export default Analytics;
