import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST, REMOVE_TOAST } from '../actionTypes';

export function toastsReducer(state = [], action) {
  switch (action.type) {
    case SHOW_ERROR_TOAST:
      return createToast(state, action.payload, 'error');
    case SHOW_SUCCESS_TOAST:
      return createToast(state, action.payload, 'success');
    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== action.payload.id);
    default:
      return state;
  }
}
function createToast(state, toast, type = 'success') {
  return [...state, { ...toast, type }];
}
