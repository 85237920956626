import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { generateClearsaleSession } from '../lib/helper';

const pageTypes = [
	{ path: 'product/', type: 'product' },
	{ path: 'products/', type: 'category' },
	{ path: 'search=', type: 'search' },
	{ path: 'checkout/', type: 'checkout' },
	{ path: 'login-register', type: 'create-account' },
	{ path: '/', type: 'home' },
];

const ClearSales = ({ location }) => {
	const [pageType, setPageType] = useState(null);
	useEffect(() => {
		const page = pageTypes.find(
			(pageObj) =>
				location.pathname.includes(pageObj.path) ||
				pageObj.path === location.pathname
		);

		if (page && window.csdm) {
			setPageType(page.type);
			window.csdm('send', page.type);
		}
	}, [location.pathname]);
	// useEffect(() => {
	// 	if (pageType === 'checkout') {
	// 		generateClearsaleSession();
	// 	}
	// }, [pageType]);
	return null;
};

export default withRouter(ClearSales);
