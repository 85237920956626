import React from 'react';
const modalSizes = {
  //small: 'modal-sm',
  large: 'modal-lg',
  'extra-large': 'modal-xl'
};

class Modal extends React.Component {
  onClose = (data = {}) => {
    if (this.props.item.onClose) {
      this.props.item.onClose({ ...data });
    }
    this.props.onClose(this.props.item.id);
  };
  onConfirm = () => {
    if (this.props.item.onConfirm) {
      this.props.item.onConfirm();
    }
    this.props.onClose(this.props.item.id);
  };

  render() {
    const { item } = this.props;
    const modalSize =
      item.size && modalSizes[item.size] ? modalSizes[item.size] : '';
    //const modalSize = item.size ? item.size === 'large' ? item.size === 'small' ? 'modal-sm' : 'modal-lg' : '';
    //const content = React.cloneElement(content, )
    return (
      <div
        className={'modal fade show'}
        role='dialog'
        style={{ display: 'block' }}>
        <div className={`modal-dialog ${modalSize}`}>
          <div className='modal-content'>
            <div className='modal-header'>
              {item.title ? <h5 className='modal-title'>{item.title}</h5> : ''}
              <button type='button' onClick={this.onClose} className='close'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              {item.type === 'confirmation'
                ? item.confirmationText ||
                  'Please confirm if you would like to proceed?'
                : React.cloneElement(item.content, {
                    closeModal: this.onClose,
                    ...this.props.props
                  })}
            </div>
            {item.showCloseBtn || item.type === 'confirmation' ? (
              <div className='modal-footer'>
                {item.type === 'confirmation' ? (
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={this.onConfirm}>
                    Confirm
                  </button>
                ) : (
                  ''
                )}
                <button
                  onClick={this.onClose}
                  type='button'
                  className='btn btn-default'>
                  Close
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Modal;
