import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import loadable from '@loadable/component';
import Loading from '../../../theme/components/loading';

const AsyncSellerRegisterContainer = () =>
	loadable(() =>
        import(
            /* webpackChunkName: "sellerContainer" */ '../../../theme/containers/seller'
        ), {
        fallback: (() => <Loading />)(),
    });
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AsyncSellerRegisterContainer())
);
