/* eslint-disable import/no-cycle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

import { ProductFilterModel } from '../models/categories';
import { UserModel } from '../models/user';
import { apiServiceMiddleware } from '../services/apiService';
// import { apiServiceMiddleware } from '../services/apiService';
// import { routerMiddleware } from 'connected-react-router';
const defaultStore = {
  app: {
    settings: {
      language: 'en',
      currency_code: 'USD',
      currency_symbol: '$',
      currency_format: '${amount}',
      thousand_separator: '',
      decimal_separator: '.',
      decimal_number: 2,
      timezone: 'Asia/Singapore',
      date_format: 'MMMM D, YYYY',
      time_format: 'h:mm a',
      weight_unit: 'kg',
      length_unit: 'cm',
    },
    categoryDetails: {},
    productDetails: {},
    categories: [],
    products: [],
    productsTotalCount: 0,
    productsHasMore: false,
    productsMinPrice: 0,
    productsMaxPrice: 0,
    productsAttributes: [],
    paymentMethods: [],
    shippingMethods: [],
    loadingProducts: false,
    loadingMoreProducts: false,
    loadingShippingMethods: false,
    loadingPaymentMethods: false,
    processingCheckout: false,
    productFilter: new ProductFilterModel(),
    // cart: cart,
    // order: null,
    // checkoutFields: checkoutFields,
    // themeSettings: themeSettings
  },
  form: {},
  toasts: [],
  modals: [],
  user: new UserModel(),
};

export const configureStore = (preloadedState = null) => {
  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const middleware = [apiServiceMiddleware, thunk];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const store = createStore(
    reducers,
    preloadedState || defaultStore,
    composedEnhancers
    // compose(
    // 	//routerMiddleware(history),
    // 	//apiServiceMiddleware,
    // 	applyMiddleware(thunk, apiServiceMiddleware)
    // 	// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // )
    // rootReducer,
    // preloadedState,
    // applyMiddleware(thunk)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
