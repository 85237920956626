import actions from 'redux-form/lib/actions';
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_REGISTER,
  USER_UPDATE,
  SET_CURRENT_USER,
  AUTHENTICATE,
  REMOVE_CURRENT_USER,
  SET_CURRENT_TOKENS,
  UPDATE_ACCESS_TOKEN,
  SET_USER_AVATAR,
} from '../actionTypes';

export function userReducer(state = {}, action) {
  switch (action.type) {
    // case USER_LOGIN:
    // case USER_REGISTER:
    //   return { ...action.payload };
    // case USER_LOGOUT:
    //   return { isUserLoggedIn: false, ...action.payload };
    case AUTHENTICATE:
      return { ...state, isUserLoggedIn: action.payload.isUserLoggedIn };
    case USER_UPDATE:
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_CURRENT_USER:
      return {};
    case UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case SET_CURRENT_TOKENS:
      return action.payload.refresh_token
        ? {
            ...state,
            access_token: action.payload.access_token,
            refresh_token: action.payload.refresh_token,
          }
        : {
            ...state,
            access_token: action.payload.access_token,
          };
    case SET_USER_AVATAR:
      return {...state, avatar: action.payload}
    default:
      return state;
  }
}
