import AsyncIndexContainer from './containers/index';
import AsyncCategoryContainer from './containers/category';
import AsyncSearchContainer from './containers/search';
import AsyncProductContainer from './containers/product';
import AsyncLoginRegisterContainer from './containers/loginRegister';
import AsyncCreateEditItemContainer from './containers/createEditItem';
import AsyncSellerContainer from './containers/seller';
import AsyncSellerRegisterContainer from './containers/sellerRegister';
import AsyncUserAccountContainer from './containers/userAccount';
import AsyncSwapsContainer from './containers/swaps';
import AsyncSwapContainer from './containers/swap';
import AsyncCheckoutContainer from './containers/checkout';
import AsyncSettingsContainer from './containers/settings';
import AsyncPurchasesContainer from './containers/purchases';
import AsyncPrintSwapContainer from './containers/printSwap';
import AsyncEmailVerifyContainer from './containers/emailVerify';
import notfound from './containers/notfound';
import ContactForm from './containers/contactForm';
import privacyPolicy from './containers/privacyPolicy';
import TermOfUse from './containers/termOfUse';
import Sitemap from './containers/sitemap';
import HowItWorks from './containers/HowItWorks';

import ForgotPassword from '../../theme/components/loginRegister/forgotPassword';
import ResetPassword from '../../theme/components/loginRegister/resetPassword';
import {
  API_ENDPOINT_CATEGORIES,
  API_ENDPOINT_PRODUCT,
  API_ENDPOINT_GET_SELLER_DETAILS,
} from './services/apiEndpoints';
import apiService from './services/apiService';

const routes = [
  {
    path: '/products/:categoryId',
    component: AsyncCategoryContainer,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/products',
    component: AsyncCategoryContainer,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/product/:productId',
    component: AsyncProductContainer,
    exact: true,
    isPrivate: false,
    preloadData: (match) =>
      apiService.request(
        'GET',
        `${API_ENDPOINT_PRODUCT}/${match.params.productId}`,
        {},
        false
      ),
  },
  {
    path: '/search',
    component: AsyncSearchContainer,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/account/:username/:type(feedback|favorites|items)?',
    component: AsyncUserAccountContainer,
    exact: true,
    isPrivate: false,
    preloadData: (match) =>
      apiService.request(
        'GET',
        `${API_ENDPOINT_GET_SELLER_DETAILS}/${match.params.username}`,
        null,
        false
      ),
  },
  {
    path: '/login-register',
    component: AsyncLoginRegisterContainer,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/items/:itemId',
    component: AsyncCreateEditItemContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/seller/register',
    component: AsyncSellerRegisterContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/seller/:action?',
    component: AsyncSellerContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/swaps/:swapId/checkout/:step(address|payment|review)?',
    component: AsyncCheckoutContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/swaps/item/:itemId',
    component: AsyncSwapsContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/swaps/:type(buy|sell)/:scope?',
    component: AsyncSwapsContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/swaps/:swapId',
    component: AsyncSwapContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/settings/:type(account|addresses|payment-methods)',
    component: AsyncSettingsContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/print',
    component: AsyncPrintSwapContainer,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/purchases',
    component: AsyncPurchasesContainer,
    exact: true,
    isPrivate: true,
  },

  {
    path: '/contactus',
    component: ContactForm,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/terms-of-use',
    component: TermOfUse,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/privacy-policy',
    component: privacyPolicy,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/sitemap',
    component: Sitemap,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/howitworks',
    component: HowItWorks,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/password/reset/:token',
    component: ResetPassword,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/email/verify/:token',
    component: AsyncEmailVerifyContainer,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/',
    component: AsyncIndexContainer,
    exact: true,
    strict: true,
    isPrivate: false,
    chunkName: 'home',
    preloadData: (match) =>
      apiService.request('GET', `categories/featured`, null, false),
  },
  {
    path: '*',
    component: notfound,
    exact: true,
    isPrivate: false,
  },
];
export default routes;
