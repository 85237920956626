import React from 'react';

const Tab = ({ tabId, activeTab, label, onClick }) => {
  return (
    <div
      className={`flex-sm-fill text-sm-center nav-link ${
        activeTab === tabId ? 'active' : ''
      }`}
      onClick={() => onClick(tabId)}
      style={{ cursor: 'pointer' }}
    >
      {label}
    </div>
  );
};

export default Tab;
