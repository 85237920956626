import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
// import { NavLink } from 'react-router-dom';
import apiService from '../../../store/shared/services/apiService';
import {
  // API_ENDPOINT_FORGOTPASSWORD,
  API_ENDPOINT_EMAIL_TO_RESET_PASSWORD
} from '../../../store/shared/services/apiEndpoints';
// import Login from './login';
// import ResetPassword from './resetPassword';

class ForgotPassword extends React.Component {
  validateEmail(value) {
    let error;
    if (!value) {
      error = ' Registered email is a required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Please enter a valid registered email address';
    }
    return error;
  }

  render() {
    return (
      <Fragment>
        <div className='row '>
          <div className='col'>
            <div className='card m-auto'>
              <div className='card-body '>
                {/* <h5 className='card-title col'>Welcome Back!</h5> */}
                <div className='card-text'>
                  <Formik
                    initialValues={{
                      email: ''
                    }}
                    onSubmit={values => {
                      // console.log('reset email value', values);

                      apiService
                        .request(
                          'POST',
                          API_ENDPOINT_EMAIL_TO_RESET_PASSWORD,
                          values
                        )
                        .then(resp => {
                          document.getElementById('email').innerHTML = '';
                        })
                        .catch(err => {
                          console.log(err);
                        });
                    }}
                  >
                    {({ errors, touched, isValid }) => (
                      <Form method='post'>
                        <div className='form-group'>
                          <label>
                            <b>Registered Email Address</b>
                          </label>
                          <Field
                            className='form-control'
                            name='email'
                            id='email'
                            validate={this.validateEmail}
                          />
                          {errors.email && touched.email && (
                            <div>{errors.email}</div>
                          )}
                        </div>

                        <button
                          type='submit'
                          className='btn btn-outline-primary mr-2'
                          disabled={!isValid}
                        >
                          Reset Password
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className='card-text' />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ForgotPassword;
