import React from 'react';
import { number, bool, string, oneOfType } from 'prop-types';
import playStore from '../../assets/images/googleplay.webp';
import { ReactComponent as Star } from '../../assets/svgs/star.svg';
import { ReactComponent as Apple } from '../../assets/svgs/AppleDownload.svg';

function getTheApp({ width, row, height, color }) {
  return (
    <div
      className={
        row
          ? 'd-flex row justify-content-center align-items-center overflow-hidden mb-3'
          : 'block'
      }
    >
      <a
        href="https://apps.apple.com/us/app/pc-swaps/id1497303232?itsct=apps_box&amp;itscg=30200"
        target="_blank"
        rel="noreferrer"
      >
        <Apple
          alt="Download PC Swaps on the App Store"
          style={{ width, height }}
          className={row ? 'mr-3' : 'mb-2'}
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.pcswaps.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noreferrer"
      >
        <img
          style={{ width, height }}
          alt="Get it on Google Play"
          src={playStore}
        />
      </a>

      {row && (
        <div className="d-none d-sm-block ml-3" style={{ color: color }}>
          <div className="m-0 p-0" style={{ textAlign: 'center'}}>
            <Star style={{ height: 14, width: 14 }} color="#FCB300" />
            <Star style={{ height: 14, width: 14 }} color="#FCB300" />
            <Star style={{ height: 14, width: 14 }} color="#FCB300" />
            <Star style={{ height: 14, width: 14 }} color="#FCB300" />
            <Star style={{ height: 14, width: 14 }} color="#FCB300" />
          </div>
          5 Star Ratings!
        </div>
      )}
    </div>
  );
}

getTheApp.propTypes = {
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  row: bool,
  color: string,
};

getTheApp.defaultProps = {
  width: 130,
  height: 'auto',
  row: false,
  color: 'white',
};

export default getTheApp;
