import queryString from 'query-string';
import apiService from './apiService';
import history from '../history';

import {
  API_ENDPOINT_PRODUCTS,
  API_ENDPOINT_FACET_ITEMS,
} from './apiEndpoints';
import { ProductFilterModel } from '../models/categories';

const setQuery = (history, query) => {
  if (history && history.location) {
    const newLocation = `${history.location.pathname}?${queryString.stringify(
      query
    )}`;
    history.push(newLocation);
  }
};
export const pageAction = {
  // setSearch: (search: any) => {
  //   const query = queryString.parse(history.location.search);
  //   query.search = search;
  //   setQuery(history, query);
  // },
  //   setSort: (sort: any) => {
  //     dispatch(setSort(sort));
  //   },
  setPriceFromAndTo: (priceFrom, priceTo) => {
    const query = queryString.parse(history.location.search);
    query.price_from = priceFrom;
    query.price_to = priceTo;
    setQuery(history, query);
  },
  setPriceFrom: (priceFrom) => {
    const query = queryString.parse(history.location.search);
    query.price_from = priceFrom;
    setQuery(history, query);
  },
  setPriceTo: (priceTo) => {
    const query = queryString.parse(history.location.search);
    query.price_to = priceTo;
    setQuery(history, query);
  },
  setFilterAttribute: (name, value) => {
    const query = queryString.parse(history.location.search);
    const queryKey = `attributes.${name}`;

    if (query[queryKey]) {
      if (Array.isArray(query[queryKey])) {
        query[queryKey].push(value);
      } else {
        query[queryKey] = [query[queryKey], value];
      }
    } else {
      query[queryKey] = [value];
    }

    setQuery(history, query);
  },
  unsetFilterAttribute: (name, value) => {
    const query = queryString.parse(history.location.search);
    const queryKey = `attributes.${name}`;
    const values = query[queryKey];

    if (values) {
      if (Array.isArray(values)) {
        query[queryKey] = values.filter((v) => v !== value);
      } else {
        query[queryKey] = undefined;
      }
    }

    setQuery(history, query);
  },
};

export const getProductFilterForCategory = (locationSearch, sortBy) => {
  const queryFilter = queryString.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  const attributes = {};
  for (const querykey in queryFilter) {
    if (querykey.startsWith('attributes.')) {
      attributes[querykey] = queryFilter[querykey];
    }
  }

  return {
    priceFrom: parseInt(queryFilter.price_from || 0),
    priceTo: parseInt(queryFilter.price_to || 0),
    attributes,
    search: null,
    sort: sortBy,
  };
};

export const getProductFilterForSearch = (locationSearch) => {
  const queryFilter = queryString.parse(locationSearch);
  return {
    categoryId: null,
    priceFrom: parseInt(queryFilter.price_from || 0),
    priceTo: parseInt(queryFilter.price_to || 0),
    search: queryFilter.search,
    sort: 'search',
  };
};

export const getParsedProductFilter = (productFilter) => {
  const filter = {
    on_sale: productFilter.onSale,
    search: productFilter.search,
    category_id: productFilter.categoryId,
    price_from: productFilter.priceFrom,
    price_to: productFilter.priceTo,
    sort: productFilter.sort,
    fields: productFilter.fields,
    limit: productFilter.limit,
    offset: 0,
    ...productFilter.attributes,
  };

  return filter;
};

export const apiFetchProducts = (productFilter) =>
  new Promise((resolve, reject) => {
    apiService
      .request('GET', API_ENDPOINT_FACET_ITEMS, productFilter)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
