import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Modals from './containers/modals';
import Toasts from './containers/toasts';
import './fontawesomeLibrary';

import routes from './routes';

import { isServer } from './redux/store';
import SharedContainer from '../../theme/containers/shared';

ReactGA.initialize('UA-146189957-1');

const App = ({ preloadedData = {}, user }) => {
  const { isUserLoggedIn } = user;
  return (
    <SharedContainer>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            render={(props) =>
              route.isPrivate && !isUserLoggedIn ? (
                <Redirect
                  to={{
                    pathname: '/login-register',
                    state: { from: props.location },
                  }}
                />
              ) : (
                <route.component
                  {...props}
                  preloadedData={preloadedData && preloadedData.data}
                />
              )
            }
          />
        ))}
      </Switch>
      {!isServer && (
        <>
          <Modals />
          <Toasts />
        </>
      )}
    </SharedContainer>
  );
};

const renderStateToProps = (state) => ({ user: state.user });
export default connect(renderStateToProps, null)(App);
