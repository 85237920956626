/* eslint-disable react/button-has-type */
import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
// import { GoogleLogin } from 'react-google-login';
// import FacebookAuth from 'react-facebook-auth';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import AuthService from '../../../store/shared/services/authServices';
import ForgotPassword from './forgotPassword';
import { loginUser } from '../../../store/shared/redux/actions/userAction';
import MetaTags from '../MetaTags';
// const Login = props =>

class Login extends React.Component {
  emailID = React.createRef();

  password = React.createRef();

  constructor() {
    super();
    this.state = {
      isSubmitting: false,
      showForgotPasswordForm: false,
    };
  }

  // submitData = event => {
  //   event.preventDefault();
  //   this.setState({ isSubmitting: true });
  //   // console.log(this.emailID.current.value);
  //   // console.log(this.password.current.value);
  //   let data = {
  //     email_username: this.emailID.current.value,
  //     password: this.password.current.value
  //   };

  //   //apiService.request("POST", API_ENDPOINTS.LOGIN, data);
  //   AuthService.authenticate(data)
  //     .then(resp => {
  //       this.props.onSuccess(resp.data.user);
  //     })
  //     .catch(err => console.log(err))
  //     .finally(this.setState({ isSubmitting: false }));
  //   document.getElementById('loginForm').reset();
  // };
  toggleForgotPasswordForm = (flag = false) => {
    this.setState({ showForgotPasswordForm: flag });
  };

  requiredFieldValidat(value) {
    let error;
    if (!value) {
      error = 'Please fill all the required fields';
    }
    return error;
  }

  render() {
    if (this.state.showForgotPasswordForm === true) {
      return (
        <>
          <ForgotPassword />

          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={this.toggleForgotPasswordForm}
          >
            Back To Login
          </button>
        </>
      );
    }
    return (
      <>
        <div className="row ">
          <div className="col">
            <div className="card m-auto">
              <div className="card-body ">
                <h5 className="card-title col">Welcome Back!</h5>

                <div className="card-text">
                  <Formik
                    initialValues={{
                      // Inital values ARE TESTING ACCORDING TO PATTERNS
                      email_username: '',
                      password: '',
                    }}
                    onSubmit={(values) => {
                      // same shape as initial values
                      // console.log(values);

                      // No longer than 15 characters, no spaces, no special characters
                      // Password must be at least 8 non-whitespace characters

                      this.props
                        .loginUser({
                          ...values,
                          email_username: values.email_username.toLocaleLowerCase(),
                          grant_type: 'client_credentials',
                        })
                        .then((resp) => {
                          this.props.onSuccess(resp.data);
                          // document.loginForm.reset();

                          // console.log(resp.data);
                        })
                        .catch((err) => {
                          // console.log(err);
                          // document.loginForm.reset();
                        })
                        .finally(this.setState({ isSubmitting: false }));
                    }}
                  >
                    {({ errors, touched, isValid }) => (
                      <Form name="loginForm" method="post">
                        <div className="form-group">
                          <label>
                            <b>Email Address or Username</b>
                          </label>
                          <Field
                            className="form-control"
                            name="email_username"
                            validate={this.requiredFieldValidat}
                          />
                          {errors.email_username && touched.email_username && (
                            <div>{errors.email_username}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>
                            <b>Password</b>
                          </label>
                          <Field
                            className="form-control"
                            name="password"
                            type="password"
                            // ref={this.password}
                            validate={this.requiredFieldValidat}
                          />
                          {errors.password && touched.password && (
                            <div>{errors.password}</div>
                          )}
                        </div>
                        <div className="form-group float-right">
                          <button
                            type="button"
                            className="btn btn-link btn-sm"
                            onClick={() => this.toggleForgotPasswordForm(true)}
                          >
                            Forgot Password?
                          </button>
                        </div>

                        {this.state.isSubmitting ? (
                          <button className="btn btn-outline-primary mr-2">
                            Please Wait...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-outline-primary mr-2"
                            disabled={!isValid}
                          >
                            Login
                          </button>
                        )}
                        <br />
                        {/* <GoogleLogin
                          clientId="your_client_id"
                          render={() => (
                            <button
                              className="btn btn-outline-primary mt-2"
                              style={{ marginRight: '8px' }}
                            >
                              Login with <FontAwesomeIcon icon={faGoogle} />
                            </button>
                          )}
                          onSuccess={(response) => {
                            // same shape as initial values
                            // console.log(values);
                            // No longer than 15 characters, no spaces, no special characters
                            // Password must be at least 8 non-whitespace characters
                            // this.props.loginUser({
                            // email_username: response.profileObj.email,
                            // grant_type: 'client_credentials',
                            // });
                            // .then((resp) => {
                            //   this.props.onSuccess(resp.data);
                            //   // document.loginForm.reset();
                            //   console.log(resp.data);
                            // })
                            // .catch((err) => {
                            //   // console.log(err);
                            //   // document.loginForm.reset();
                            // })
                            // .finally(this.setState({ isSubmitting: false }));
                          }}
                          onFailure={console.log}
                          isSignedIn
                        />
                        <FacebookAuth
                          appId="your app id"
                          component={() => (
                            <button className="btn btn-outline-primary mt-2">
                              Login with <FontAwesomeIcon icon={faFacebook} />
                            </button>
                          )}
                        /> */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { loginUser })(Login);
