export const SORTING = {
  NAME: 'name',
  NEWEST: '-created_at',
  PRICELOW: 'price',
  PRICEHIGH: '-price',
};
export const SITE_TAGLINE = 'Where Computer Enthusiasts Buy and Sell Parts';
export const SITE_URL = process.env.RAZZLE_APP_URL;
export const EXTERNAL_LINK_NAVIGATION = 'external-link';
export const BUTTON_NAVIGATION = 'button';
