import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './appReducer';
import { toastsReducer } from './toastsReducer';
import { modalReducer } from './modalReducer';
import { userReducer } from './userReducer';

export default combineReducers({
  app: appReducer,
  toasts: toastsReducer,
  modals: modalReducer,
  form: formReducer,
  user: userReducer,
});
