import React from 'react';

export default function Loading({ size }) {
  return (
    <div className="text-center">
      <div
        className={`spinner-border ${
          size === 'small' ? 'spinner-border-sm' : ''
        }`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
