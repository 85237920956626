import { Product } from './product';
import { User, Address } from './user';

export class SwapModel {
	_id;
	state;
	label;
	has_unread;
	current_offer_id;
	updated_at;
	archived;
	can_archive;
	tracking_url;
	tracking_number;
	print_url;
	conversion_value;
	created_at;
	buyer = new User();
	seller = new User();
	item = new Product();
	offers = [];
	ship_to_address = new Address();
	ship_from_address = new Address();
	transaction = new Transaction();
	tracker = new Tracker();
}
export class Offer {
	_id;
	state;
	state_detailed;
	quantity;
	created_at;
	updated_at;
	expires_at;
	itemization = new PriceDetails();
}
export class PriceDetails {
	price;
	shipping;
	total;
}
export class Transaction {
	_id;
	type;
	state;
	label;
	amount;
	name;
	memo;
	//help_url: null,
	created_at;
	subtotals = [];
	swap_id;
}
export class TransactionSubtotal {
	amount;
	label;
}
export class Tracker {
	slug;
	name;
	code;
	url;
}
export const SWAP_NEW_STATE = 'new';
export const SWAP_CREATED_STATE = 'created';
export const SWAP_WITHDRAWN_STATE = 'withdrawn';
export const SWAP_MESSAGE_STATE = 'message';
export const SWAP_OFFER_STATE = 'offer';
export const SWAP_REJECTED_STATE = 'rejected';
export const SWAP_PROCESSING_STATE = 'processing';
export const SWAP_ON_HOLD_STATE = 'on-hold';
export const SWAP_APPROVED_STATE = 'approved';
export const SWAP_SHIPMENT_STATE = 'shipment';
export const SWAP_SHIPPED_STATE = 'shipped';
export const SWAP_RECEIVED_STATE = 'received';
export const SWAP_DISPUTE_STATE = 'dispute';
export const SWAP_CANCELED_STATE = 'canceled';
export const SWAP_COMPLETED_STATE = 'completed';
export const SWAP_EXPIRED_STATE = 'expired';

export const SWAP_ACTION_TRACK = 'track';
export const SWAP_ACTION_PRINT_LABEL = 'print_label';
export const SWAP_ACTION_SHIPMENT = 'shipment';
export const SWAP_ACTION_SHIPPED = 'shipped';
export const SWAP_ACTION_OFFER = 'offer';
export const SWAP_ACTION_BUY = 'buy';
export const SWAP_ACTION_ACCEPT_OFFER = 'accepted';
export const SWAP_ACTION_REJECT_OFFER = 'rejected';
export const SWAP_ACTION_WITHDRAW = 'withdrawn';
export const SWAP_ACTION_RECEIVED = 'received';
export const SWAP_ACTION_ACCEPT_REJECT = 'accept_reject';
export const SWAP_ACTION_COMPLETED = 'completed';
export const SWAP_ACTION_FEEDBACK = 'feedback';
