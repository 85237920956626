import { SITE_URL } from '../models/misc';

export const getProductUrl = (id, fullLink) => {
	return `${fullLink ? SITE_URL : ''}/product/${id}`;
};
export const getShopUrl = () => {
	return `/products`;
};
export const getProductCategoyUrl = (id, params) => {
	if (params) {
		let paramsArray = Object.entries(params).map((value, key) => {
			return `${key}=${value}`;
		});
		return `products/${id}/?${paramsArray.join('&')}`;
	}
	return `/products/${id}/`;
};
export const getProductUploadUrl = () => {
	return `/items/new`;
};
export const getLoginRegisterUrl = () => {
	return '/login-register';
};
export const getAccountUrl = () => {
	return 'account';
};
export const swapListPageUrl = (type = 'buy', scope = 'negotiations') => {
	return `/swaps/${type}/${scope}`;
};
export const checkOutpageUrl = (
	swapId,
	step,
	offerType = 'buy',
	offerAmount
) => {
	const amountQuery = offerAmount ? `&offerAmount=${offerAmount}` : '';
	return `/swaps/${swapId}/checkout/${step}?offerType=${offerType}${amountQuery}`;
};
export const swapPageUrl = swapId => {
	return `/swaps/${swapId}`;
};
export const ItemSwaps = itemId => {
	return `/swaps/item/${itemId}`;
};
