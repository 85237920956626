import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import apiService from '../services/apiService';

const ContactForm = props => {
  let userName = React.createRef();
  let emailAdd = React.createRef();
  let contactNo = React.createRef();
  let subject = React.createRef();
  let message = React.createRef();

  const formSubmit = e => {
    e.preventDefault();

    let contactFormData = {
      name: userName.current.value,
      email: emailAdd.current.value,
      contactNo: contactNo.current.value,
      subject: subject.current.value,
      message: message.current.value
    };

    console.log(contactFormData, 'data from contact form');
    e.currentTarget.reset();

    apiService
      .request('POST', '', contactFormData)
      .then(
        resp => {
          console.log(resp.data);
        },
        err => {
          console.log(err.data);
        }
      )
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col-sm-6 offset-sm-3 '>
          <div className='contactForm '>
            <div className='card '>
              <div className='card-body'>
                <h5 className='card-title '>
                  Contact Us For More Details and Queries
                </h5>

                <div className='card-text'>
                  <form className=' contactForm form ' onSubmit={formSubmit}>
                    <div className='form-group'>
                      <label> Full Name</label>
                      <input
                        className='form-control '
                        type='text'
                        name='userName'
                        ref={userName}
                      />
                    </div>

                    <div className='form-group'>
                      <label> Email Address</label>
                      <input
                        className='form-control'
                        type='text'
                        name='emailAdd'
                        ref={emailAdd}
                      />
                    </div>
                    <div className='form-group'>
                      <label> Contact Number</label>
                      <input
                        className='form-control'
                        type='text'
                        name='contactNo'
                        ref={contactNo}
                      />
                    </div>

                    <div className='form-group'>
                      <label> Subject</label>
                      <input
                        className='form-control'
                        type='text'
                        name='subject'
                        ref={subject}
                      />
                    </div>

                    <div className='form-group'>
                      <label>Message</label>
                      <textarea
                        className='form-control'
                        type='text'
                        name='message'
                        ref={message}
                      />
                    </div>

                    <button type='submit' className='btn btn-primary '>
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(ContactForm);
