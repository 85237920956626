import React from 'react';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import Loading from '../../../theme/components/loading';
const AsyncCategoryContainer = () =>
  loadable(() =>
    import(
      /* webpackChunkName: "category" */ '../../../theme/containers/category'
    ), {
    fallback: (() => <Loading />)(),
  });
export default withRouter(AsyncCategoryContainer());
