import React from 'react';
import { loadFreshChat } from '../lib/helper';
import { connect } from 'react-redux';
import apiService from '../../store/shared/services/apiService';
import { userUpdate } from '../../store/shared/redux/actions/userAction';
class FreshChat extends React.PureComponent {
	componentDidMount() {
		loadFreshChat(this.toggleFreshChatWidget);
	}
	componentDidUpdate(prevProps) {
		if (this.props.user.isUserLoggedIn !== prevProps.user.isUserLoggedIn) {
			this.toggleFreshChatWidget();
		}
	}
	toggleFreshChatWidget = () => {
		const freshChatWidget = window.fcWidget;
		const { user } = this.props;
		if (!freshChatWidget) return;
		if (user.isUserLoggedIn && !freshChatWidget.isInitialized()) {
			window.fcWidget.init({
				token: 'dc4cf5b2-afcd-41f8-821d-bbabe6eaf7f6',
				host: 'https://wchat.freshchat.com',
				externalId: user.user_id, // user’s id unique to your system
				firstName: user.username, // user’s first name
				email: user.email, // user’s email address
				restoreId: user.freshdesk_id || null,
			});
			// window.fcWidget.on('user:created', function(resp) {
			//   var status = resp && resp.status,
			//     data = resp && resp.data;
			//   if (status === 200) {
			//     if (data.restoreId) {
			//       apiService.request(
			//         'PUT',
			//         'users/freshdesk',
			//         { freshdesk_id: data.restoreId },
			//         false
			//       );
			//       userUpdate({ freshdesk_id: data.restoreId });
			//       // Update restoreId in your database
			//     }
			//   }
			// });
			window.fcWidget.user.get(function (resp) {
				const status = resp && resp.status;

				if (status !== 200) {
					window.fcWidget.user.setProperties({
						firstName: user.username, // user's first name
						//lastName: "Doe",                // user's last name
						email: user.email, // user's email address
					});
					window.fcWidget.on('user:created', function (resp) {
						var status = resp && resp.status,
							data = resp && resp.data;
						if (status === 200) {
							if (data.restoreId) {
								updateFreshdeskId(data.restoreId);
								// Update restoreId in your database
							}
						}
					});
				} else if (!user.freshdesk_id) {
					const data = resp && resp.data;
					if (data.restoreId) {
						updateFreshdeskId();
					}
				}
			});
		} else {
			freshChatWidget.destroy();
		}
	};
	render() {
		return '';
	}
}
const updateFreshdeskId = (freshdesk_id) => {
	apiService.request(
		'PUT',
		'users/freshdesk',
		{ freshdesk_id: freshdesk_id },
		false
	);
	userUpdate({ freshdesk_id: freshdesk_id });
};
const mapStateToProps = (state) => {
	return { user: state.user };
};
export default connect(mapStateToProps, { userUpdate })(FreshChat);
