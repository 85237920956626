import React from 'react';


const PageTitleContainer=(props)=>{
    return(
        <section className='hero is-light page-title-wrapper'>
        <div className='jumbotron jumbotron-fluid bg-primary'>
          <div className='container'>
            <h1 className='category-title display-4'>{props.title}</h1>
          </div>
        </div>
      </section>
    );

}

export default  PageTitleContainer;