import React, { useState, useEffect } from 'react';
import Tab from './tab';

const Tabs = ({ children, defaultActiveTab = 0 }) => {
  const [activeTab, toggleActiveTab] = useState(defaultActiveTab);
  const onCLickTabItem = tabId => {
    toggleActiveTab(tabId);
  };
  useEffect(() => {
    toggleActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);
  return (
    <div className='tabs'>
      <nav className='nav nav-pills flex-column flex-sm-row mb-4'>
        {children.map((child, index) => {
          if (!child) return undefined;
          const { label } = child.props;
          return (
            <Tab
              activeTab={activeTab}
              key={index}
              tabId={index}
              label={label}
              onClick={onCLickTabItem}
            />
          );
        })}
      </nav>
      <div className='tab-content' id='myTabContent'>
        {children.map((child, index) => {
          if (!child || index !== activeTab) return undefined;
          return (
            <div key={index} className='tab-pane fade show active' role='tabpanel'>
              {child.props.children}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
