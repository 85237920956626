import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODALs } from '../actionTypes';

export function modalReducer(state = [], action) {
  switch (action.type) {
    case OPEN_MODAL:
      return [...state, { ...action.payload }];
    case CLOSE_MODAL:
      return state.filter(item => item.id !== action.payload.id);
    case CLOSE_ALL_MODALs:
      return [];
    default:
      return state;
  }
}
