/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import DarkmodeToggler from './darkmodeToggler';

import {
  getProductUploadUrl,
  getLoginRegisterUrl,
  swapListPageUrl,
} from '../../../store/shared/services/linkCreatorService';
import { StateContext } from '../../../store/shared/context';
import { logoutUser } from '../../../store/shared/redux/actions/userAction';
// import "../../assets/scss/index.scss";
import placeholderImg from '../../../images/userImage.webp';

import MobileHeadMenu from './mobileHeadMenu';
import {
  EXTERNAL_LINK_NAVIGATION,
  BUTTON_NAVIGATION,
} from '../../../store/shared/models/misc';

const HeadTopMenu = (props) => {
  const topMenuLinkList = (username) => [
    { name: 'My Setup', value: 'My Setup', to: `/account/${username}` },
    {
      name: 'Seller Dashboard',
      value: 'Seller Dashboard',
      to: '/seller',
    },
    { name: 'Purchases', value: 'Purchases', to: '/purchases' },

    { name: 'Settings', value: 'Settings', to: '/settings/account' },
    {
      name: 'Help Center',
      value: 'Help ?',
      to: 'https://blog.pcswaps.com/',
      type: EXTERNAL_LINK_NAVIGATION,
    },

    {
      name: 'Logout',
      value: 'Logout',
      type: BUTTON_NAVIGATION,
      callback: props.logoutUser,
    },
  ];

  const avatar = useSelector((state) => state.user.avatar);

  const {
    user: { isUserLoggedIn, username },
  } = useContext(StateContext);

  const [mobileMenuIsActive, toggleMobileMenu] = useState(false);
  const menuToggle = () => {
    toggleMobileMenu(!mobileMenuIsActive);
  };

  return (
    <>
      <MobileHeadMenu
        isMobile
        mobileMenuIsActive={mobileMenuIsActive}
        data={topMenuLinkList(username)}
        onClick={() => toggleMobileMenu(false)}
        logoutDirection
        direction="right"
      />
      <DarkmodeToggler />
      {/* </div> */}
      {/* <a className="btn btn-link" href="https://shop.pcswaps.com/">
        Merch Shop
      </a> */}
      <Link
        className="btn btn-outline-primary btn-sm "
        to={getProductUploadUrl()}
      >
        Sell
      </Link>
      {isUserLoggedIn ? (
        <>
          <Link
            className="btn btn-link "
            to={{
              pathname: swapListPageUrl(),
            }}
          >
            My Swaps
          </Link>
          <div className="user-profile ">
            <div className="dropdown d-none d-md-block">
              <img
                className="dropdown-toggle avatar-user-image "
                src={!avatar ? placeholderImg : avatar}
                alt="user"
              />

              <div />
              <div className="dropdown-menu ">
                <NavLink className="dropdown-item" to={`/account/${username}`}>
                  <b>@{username}</b>
                </NavLink>
                {topMenuLinkList(username).map((detail, key) =>
                  detail.type === EXTERNAL_LINK_NAVIGATION ? (
                    <a key={key} className="dropdown-item" href={detail.to}>
                      {detail.name}
                    </a>
                  ) : detail.type === BUTTON_NAVIGATION ? (
                    <button
                      type="button"
                      key={key}
                      className="dropdown-item btn btn-link"
                      onClick={() => detail.callback()}
                    >
                      {detail.name}
                    </button>
                  ) : (
                    <NavLink key={key} className="dropdown-item" to={detail.to}>
                      {detail.name}
                    </NavLink>
                  )
                )}
              </div>
            </div>
            <img
              className="dropdown-toggle avatar-user-image d-md-none"
              // className={props.classToggle}
              // isActive={mobileMenuIsActive}
              onClick={() => toggleMobileMenu(!mobileMenuIsActive)}
              src={!avatar ? placeholderImg : avatar}
              alt="user1"
            />
          </div>
        </>
      ) : (
        <>
          <Link
            className="btn btn-link"
            to={{
              pathname: getLoginRegisterUrl(),
              state: { activeTab: 'register' },
            }}
          >
            Join
          </Link>
          <Link
            className="btn btn-link"
            to={{
              pathname: getLoginRegisterUrl(),
              state: { activeTab: 'login' },
            }}
          >
            Login
          </Link>
          <a className="btn btn-link" href="https://blog.pcswaps.com/">
            Help Center
          </a>
        </>
      )}
    </>
  );
};
// HeadTopMenu.contextTypes = {
//   UserContext: PropTypes.object
// };
// const mapDispatchToProps = dispatch => {
//   return bindActionCreators({ logoutUser }, dispatch);
// };
export default connect(null, { logoutUser })(HeadTopMenu);
