/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import queryString from 'query-string';
import React from 'react';
import * as t from './actionTypes';
// import { animateScroll } from 'react-scroll';
import { apiFetchProducts } from '../services/productsService';

import OfferContainer from '../../../theme/components/offer';

import LoginRegister from '../../../theme/components/loginRegister';
import { openModal } from './actions/modalAction';
import apiService from '../services/apiService';
import { API_ENDPOINT_SWAPS } from '../services/apiEndpoints';
import { checkOutpageUrl, swapPageUrl } from '../services/linkCreatorService';
import { SORTING } from '../models/misc';
import { showErrorToast } from './actions/toastsAction';
import { USER_ID, IS_USER_LOGGEDIN } from '../models/user';
import { isServer } from './store';
// import * as analytics from './analytics';

const requestProduct = () => ({ type: t.PRODUCT_REQUEST });

const receiveProduct = (product) => ({ type: t.PRODUCT_RECEIVE, product });

export const fetchProducts = () => (dispatch, getState) => {
  dispatch(requestProducts());
  const { app } = getState();
  const filter = getParsedProductFilter(app.productFilter);
  // const response = await api.ajax.products.list(filter);
  // const response = await api.ajax.products.list(filter);
  apiFetchProducts(filter)
    .then((resp) => {
      dispatch(receiveProducts(null));
      dispatch(receiveProducts(resp));
    })
    .catch((err) => console.log(err));
  // remove below line once connected to api
  // const products = {
  //   data: PRODUCTS,
  //   total_count: 2,
  //   has_more: false,
  //   attributes: PRODUCT_ATTRIBUTES,
  //   price: {
  //     min: 200,
  //     max: 1250
  //   },
  //   categoryDetails: CATEGORY_DETAILS
  // };
  // //   const products = response.json;
  // dispatch(receiveProducts(null));
  // dispatch(receiveProducts(products));
};

export const getProductFilterForCategory = (locationSearch, sortBy) => {
  const queryFilter = queryString.parse(locationSearch);
  const attributes = {};
  for (const querykey in queryFilter) {
    if (querykey.startsWith('attributes_') || querykey === 'condition') {
      attributes[querykey] = queryFilter[querykey];
    }
  }
  return {
    categoryId: null,
    priceFrom: parseInt(queryFilter.price_from || 0),
    priceTo: parseInt(queryFilter.price_to || 0),
    attributes,
    page: parseInt(queryFilter.page || 1),
    page_size: 21,
    search: queryFilter.search || null,
    state: queryFilter.state,
    // sort: sortBy
  };
};

export const getProductFilterForSearch = (locationSearch) => {
  const queryFilter = queryString.parse(locationSearch);

  return {
    categoryId: null,
    priceFrom: parseInt(queryFilter.price_from || 0),
    priceTo: parseInt(queryFilter.price_to || 0),
    search: queryFilter.search,
    sort: 'search',
    page: parseInt(queryFilter.page || 1),
    page_size: 9,
    state: queryFilter.state,
  };
};

export const getParsedProductFilter = (productFilter) => {
  const filter = {
    on_sale: productFilter.onSale,
    search: productFilter.search,
    category_id: productFilter.categoryId,
    price_from: productFilter.priceFrom,
    price_to: productFilter.priceTo,
    sort: productFilter.sort,
    fields: productFilter.fields,
    limit: productFilter.limit,
    offset: 0,
    page: productFilter.page,
    page_size: productFilter.page_size,
    state: productFilter.state,
    ...productFilter.attributes,
  };

  return filter;
};

const requestProducts = () => ({ type: t.PRODUCTS_REQUEST });

const receiveProducts = (products) => ({ type: t.PRODUCTS_RECEIVE, products });

export const fetchMoreProducts = () => async (dispatch, getState) => {
  const { app } = getState();
  if (
    app.loadingProducts ||
    app.loadingMoreProducts ||
    app.products.length === 0 ||
    !app.productsHasMore
  ) {
  } else {
    dispatch(requestMoreProducts());

    const filter = getParsedProductFilter(app.productFilter);
    filter.offset = app.products.length;

    //  const response = await api.ajax.products.list(filter);
    //  const products = response.json;
    //  dispatch(receiveMoreProducts(products));
    // animateScroll.scrollMore(200);
  }
};

const requestMoreProducts = () => ({ type: t.MORE_PRODUCTS_REQUEST });

const receiveMoreProducts = (products) => ({
  type: t.MORE_PRODUCTS_RECEIVE,
  products,
});
export const onBuyButtonClick = (item = {}, history) => async (
  dispatch,
  getState
) => {
  const { user } = getState();
  console.log('user on buy button click', user);
  if (!user || !user[IS_USER_LOGGEDIN]) {
    // open User modal
    dispatch(
      openModal({
        type: 'custom',
        title: 'Please login to continue',
        size: 'medium',
        content: <LoginRegister />,
        onClose: () => {
          const { user } = getState();
          if (user[USER_ID]) {
            dispatch(fetchItemSwapAndRedirect(item, history));
          }
        },
      })
    );
  } else {
    dispatch(fetchItemSwapAndRedirect(item, history));
  }
};

export const onMessageButtonClick = (item, history) => async (
  dispatch,
  getState
) => {
  const { user } = getState();
  if (!user || !user[IS_USER_LOGGEDIN]) {
    dispatch(
      openModal({
        type: 'custom',
        title: 'please Login to continue',
        size: 'medium',
        content: <LoginRegister />,
        onClose: () => {
          const { user } = getState();
          if (user[USER_ID]) {
            dispatch(fetchItemSwapAndRedirect(item, history, 'swap'));
          }
        },
      })
    );
  } else {
    dispatch(fetchItemSwapAndRedirect(item, history, 'swap'));
  }
};
export const onOfferModalButtonClick = (product = {}, history) => (
  dispatch,
  getState
) => {
  const { user } = getState();
  // console.log(user);
  if (user && user[IS_USER_LOGGEDIN]) {
    openOfferModal(product, history, dispatch);
  } else {
    dispatch(
      openModal({
        type: 'custom',
        title: '',
        size: 'medium',
        content: <LoginRegister />,
        onClose: () => {
          const { user } = getState();
          if (user[USER_ID]) {
            openOfferModal(product, history, dispatch);
          }
        },
      })
    );
  }
};
const openOfferModal = (product = {}, history, dispatch) => {
  dispatch(
    openModal({
      title: 'Make Offer',
      size: 'medium',
      type: 'custom',
      content: <OfferContainer product={{ ...product }} />,
      onClose: ({ offerAmount }) => {
        if (!offerAmount) return;
        dispatch(
          fetchItemSwapAndRedirect(product, history, 'checkout', {
            offerType: 'offer',
            offerAmount,
          })
        );
      },
    })
  );
};
const fetchItemSwapAndRedirect = (
  item,
  history,
  redirectToPage = 'checkout',
  { offerType = 'buy', offerAmount = '' } = {}
) => async (dispatch, getState) => {
  const { user } = getState();
  const sellerId = item.seller ? item.seller._id : item.seller_id;
  // confirm if user is not seller of the product
  if (sellerId == user[USER_ID]) {
    dispatch(showErrorToast('You are not authorized to perform this action!'));
  } else {
    try {
      console.log('fetch swap');
      const resp = await apiService.request('POST', API_ENDPOINT_SWAPS, {
        item_id: item._id,
      });

      const { _id } = resp.data.swap;
      const redirectUrl =
        redirectToPage === 'checkout'
          ? checkOutpageUrl(_id, '', offerType, offerAmount)
          : swapPageUrl(_id);
      history.push(redirectUrl);
      if (redirectToPage === 'checkout' && !isServer) {
        window.location.reload(true);
      }
    } catch (err) {
      // console.log('here');
      console.log(err);
    }
  }
};

export const setSort = (sort) => (dispatch, getState) => {
  dispatch(setProductsFilter({ sort }));
  dispatch(fetchProducts());
};
const setProductsFilter = (filter) => ({
  type: t.SET_PRODUCTS_FILTER,
  filter,
});

export const analyticsSetShippingMethod = (method_id) => (
  dispatch,
  getState
) => {
  const { app } = getState();
  //   analytics.setShippingMethod({
  //     methodId: method_id,
  //     allMethods: app.shippingMethods
  //   });
};

export const analyticsSetPaymentMethod = (method_id) => (
  dispatch,
  getState
) => {
  const { app } = getState();
  //   analytics.setPaymentMethod({
  //     methodId: method_id,
  //     allMethods: app.paymentMethods
  //   });
};

export const loadCategoryPage = (locationSearch, queryParams) => (dispatch) => {
  const productFilter = getProductFilterForCategory(locationSearch);
  dispatch(receiveProduct(null));
  dispatch(setProductsFilter({ ...productFilter, ...queryParams }));
  dispatch(fetchProducts());
};
export const loadSearchPage = (locationSearch) => (dispatch) => {
  const productFilter = getProductFilterForSearch(locationSearch);
  dispatch(receiveProduct(null));
  dispatch(setProductsFilter(productFilter));
  dispatch(fetchProducts());
};
