import queryString from 'query-string';
// import { getProductFilterForCategory } from '../services/productsService';

// import { getJSONLD } from './lib/jsonld';
import {
  loadCategoryPage,
  loadSearchPage,
  onOfferModalButtonClick,
  onBuyButtonClick,
  onMessageButtonClick,
  setSort,
} from './actions';
import { openModal } from './actions/modalAction';
import { showSuccessToast, showErrorToast } from './actions/toastsAction';
import {
  userLogut,
  logoutUser,
  setCurrentUser,
  setCurrentTokens,
  refreshSession,
} from './actions/userAction';

const setQuery = (history, query) => {
  if (history && history.location) {
    const newLocation = `${history.location.pathname}?${queryString.stringify(
      query
    )}`;
    history.push(newLocation);
  }
};

export const mapStateToProps = (state, ownProps) => ({
  state: { ...state.app, user: { ...state.user } },
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  // addCartItem: item => {
  //   dispatch(addCartItem(item));
  // },
  // deleteCartItem: item_id => {
  //   dispatch(deleteCartItem(item_id));
  // },
  // updateCartItemQuantiry: (item_id, quantity) => {
  //   dispatch(updateCartItemQuantiry(item_id, quantity));
  // },
  // updateCart: (data, callback) => {
  //   dispatch(updateCart(data, callback));
  // },
  // checkout: data => {
  //   dispatch(checkout(data, ownProps.history));
  // },
  // loadMoreProducts: () => {
  //   dispatch(fetchMoreProducts());
  // },
  // loadShippingMethods: () => {
  //   dispatch(fetchShippingMethods());
  // },
  // loadPaymentMethods: () => {
  //   dispatch(fetchPaymentMethods());
  // },
  // setSearch: search => {
  //   const query = queryString.parse(ownProps.history.location.search);
  //   query.search = search;
  //   setQuery(ownProps.history, query);
  // },
  refreshSession: () => {
    dispatch(refreshSession());
  },
  setUser: (user = {}) => {
    dispatch(setCurrentUser(user));
  },
  setTokens: (tokens) => {
    dispatch(setCurrentTokens(tokens));
  },
  setSort: (sort) => {
    dispatch(setSort(sort));
  },
  loadCategoryProducts: (locationSearch, queryParams = {}) => {
    dispatch(loadCategoryPage(locationSearch, queryParams));
  },
  loadSearchProducts: (locationSearch) => {
    dispatch(loadSearchPage(locationSearch));
  },
  setPriceFromAndTo: (priceFrom, priceTo) => {
    const query = queryString.parse(ownProps.history.location.search);
    query.price_from = priceFrom;
    query.price_to = priceTo;
    setQuery(ownProps.history, query);
  },
  setPriceFrom: (priceFrom) => {
    const query = queryString.parse(ownProps.history.location.search);
    query.price_from = priceFrom;
    setQuery(ownProps.history, query);
  },
  setPriceTo: (priceTo) => {
    const query = queryString.parse(ownProps.history.location.search);
    query.price_to = priceTo;
    setQuery(ownProps.history, query);
  },
  onPageChange: (page = 1, page_size = 30) => {
    const query = queryString.parse(ownProps.history.location.search);
    query.page = page;
    query.page_size = page_size;
    setQuery(ownProps.history, query);
  },
  setItemState: (state) => {
    const query = queryString.parse(ownProps.history.location.search);
    query.state = state;
    query.page_size = 30;
    query.page = 1;
    setQuery(ownProps.history, query);
  },
  setFilterAttribute: (name, value) => {
    const query = queryString.parse(ownProps.history.location.search);

    const queryKey = `attributes_${name}`;

    if (query[queryKey]) {
      if (Array.isArray(query[queryKey])) {
        query[queryKey].push(value);
      } else {
        query[queryKey] = [query[queryKey], value];
      }
    } else {
      query[queryKey] = [value];
    }
    query.page_size = 30;
    query.page = 1;
    setQuery(ownProps.history, query);
  },

  unsetFilterAttribute: (name, value) => {
    const query = queryString.parse(ownProps.history.location.search);
    const queryKey = `attributes_${name}`;
    const values = query[queryKey];

    if (values) {
      if (Array.isArray(values)) {
        query[queryKey] = values.filter((v) => v != value);
      } else {
        query[queryKey] = undefined;
      }
    }

    setQuery(ownProps.history, query);
  },
  clearFilterAttribute: (name) => {
    const query = queryString.parse(ownProps.history.location.search);
    const queryKey = `attributes_${name}`;
    delete query[queryKey];
    setQuery(ownProps.history, query);
  },
  setLocation: (path) => {
    ownProps.history.push(path);
  },
  goBack: () => {
    if (ownProps.history.length > 0) {
      ownProps.history.goBack();
    }
  },
  openModal: (payload) => {
    dispatch(openModal(payload));
  },
  openOfferModal: (payload) => {
    dispatch(onOfferModalButtonClick(payload, ownProps.history));
  },
  onBuyButtonClick: (item) => {
    dispatch(onBuyButtonClick(item, ownProps.history));
  },
  onMessageButtonClick: (item) => {
    dispatch(onMessageButtonClick(item, ownProps.history));
  },

  showSuccessToast: (payload) => {
    dispatch(showSuccessToast(payload));
  },

  showErrorToast: (payload) => {
    dispatch(showErrorToast(payload));
  },
  userLogout: () => {
    dispatch(logoutUser());
  },
  // getJSONLD: state => {
  //   return getJSONLD(state);
  // }
});
// function reducers(state, action) {
//   return { state };
// }
// export function mapDispatchToProps(dispatch) {
//   return {
//     reducers
//   };
// }
// export function mapStateToProps() {
//   return {};
// }
