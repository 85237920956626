import * as t from '../actionTypes';
import { CONDITION } from '../../models/categories';

const initialState = {};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.PRODUCT_RECEIVE:
      return { ...state, productDetails: action.product };

    case t.PRODUCTS_REQUEST:
      return { ...state, loadingProducts: true };

    case t.PRODUCTS_RECEIVE:
      if (action.products) {
        // const data = action.products;
        const { data, meta } = action.products;
        return {
          ...state,
          loadingProducts: false,
          products: data,
          // productsTotalCount: meta.facets.total_count,
          // productsHasMore: data.meta.has_more,
          productsAttributes: meta.facets.attributes || [],
          conditions: [CONDITION],
          productsMinPrice: meta.facets.price ? meta.facets.price.min : 0,
          productsMaxPrice: meta.facets.price ? meta.facets.price.max : 0,
          categoryDetails: meta.facets.categoryDetails,
          productsPaging: meta.paging,
          categories: meta.facets.categories,
        };
      }
      return { ...state, products: [] };

    case t.MORE_PRODUCTS_REQUEST:
      return { ...state, loadingMoreProducts: true };

    case t.MORE_PRODUCTS_RECEIVE:
      return {
        ...state,
        loadingMoreProducts: false,
        products: [...state.products, ...action.products.data],
        productsTotalCount: action.products.total_count,
        productsHasMore: action.products.has_more,
      };

    case t.PAGE_RECEIVE:
      return { ...state, pageDetails: action.pageDetails };

    case t.CART_RECEIVE:
      return { ...state, cart: action.cart };

    case t.SHIPPING_METHODS_REQUEST:
      return { ...state, loadingShippingMethods: true };

    case t.SHIPPING_METHODS_RECEIVE:
      return {
        ...state,
        shippingMethods: action.methods,
        loadingShippingMethods: false,
      };

    case t.PAYMENT_METHODS_REQUEST:
      return { ...state, loadingPaymentMethods: true };

    case t.PAYMENT_METHODS_RECEIVE:
      return {
        ...state,
        paymentMethods: action.methods,
        loadingPaymentMethods: false,
      };

    case t.CHECKOUT_REQUEST:
      return { ...state, processingCheckout: true };

    case t.CHECKOUT_RECEIVE:
      return {
        ...state,
        cart: null,
        order: action.order,
        processingCheckout: false,
      };

    case t.SITEMAP_RECEIVE:
      return { ...state, currentPage: action.currentPage };

    case t.SET_CURRENT_CATEGORY:
      return { ...state, categoryDetails: action.category };

    case t.SET_PRODUCTS_FILTER:
      return {
        ...state,
        productFilter: { ...state.productFilter, ...action.filter },
      };

    case t.LOCATION_CHANGED:
      return { ...state, location: action.location };

    case t.PRODUCT_REQUEST:
    case t.PAGE_REQUEST:
    case t.CART_REQUEST:
    case t.CART_ITEM_ADD_REQUEST:
    case t.CART_ITEM_DELETE_REQUEST:
    case t.CART_ITEM_UPDATE_REQUEST:
    case t.SITEMAP_REQUEST:
    default:
      return state;
  }
};
export default appReducer;
