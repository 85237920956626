import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../assets/scss/index.scss';
import '../assets/scss/styles/theme.scss';
import { animateScroll } from 'react-scroll';
import Header from '../components/header';
import Footer from '../components/header/footer';
import { StateContext } from '../../store/shared/context';
import MetaTags from '../components/MetaTags';
import { SITE_URL } from '../../store/shared/models/misc';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '../../store/shared/redux/containerProps';

class SharedContainer extends React.Component {
  componentDidMount() {
    // AuthService.setUserAuthOnPageLoad();
    // if (!isServer) {
    // 	this.props.establishCurrentUser();
    // }
    this.props.refreshSession();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location && prevProps.location) {
      const pathnameChanged =
        this.props.location.pathname !== prevProps.location.pathname;
      const queryChanged =
        this.props.location.search !== prevProps.location.search;
      const isSearchPage = this.props.location.pathname === '/search';
      if (pathnameChanged || (queryChanged && isSearchPage)) {
        animateScroll.scrollToTop({
          duration: 500,
          delay: 100,
          smooth: true,
        });
      }
    }
  }

  render() {
    return (
      <>
        <MetaTags
          title="PC Swaps - Where Computer Enthusiasts Buy and Sell PCs and Parts"
          description="PC Swaps is an online marketplace to buy and sell PCs, parts, monitors, laptops, and more! Shop the best deals on new and used hardware or sell PC hardware to start earning."
          canonicalUrl={SITE_URL}
          ogLocale="en_US"
          ogSiteName="PC Swaps"
          ogTitle="PC Swaps - Where Computer Enthusiasts Buy and Sell PCs and Parts"
          ogDescription="PC Swaps is an online marketplace to buy and sell PCs, parts, monitors, laptops, and more! Shop the best deals on new and used hardware or sell PC hardware to start earning."
          themeColor="#5b66f3"
        />

        <StateContext.Provider
          value={{
            user: { ...this.props.state.user },
            userLogout: this.props.userLogout,
            openModal: this.props.openModal,
          }}
        >
          <Header
            history={this.props.history}
            setUser={this.props.setUser}
            setTokens={this.props.setTokens}
          />
          <div className="wrapper" id="main">
            {this.props.children}
          </div>
          <Footer />
        </StateContext.Provider>
      </>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharedContainer)
);
