import React from 'react';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import Loading from '../../../theme/components/loading';
const AsyncNotFoundContainer = () =>
	loadable(() =>
        import(
            /* webpackChunkName: "NotFoundContainer" */ '../../../theme/containers/notfound'
        ), {
        fallback: (() => <Loading />)(),
    });

export default withRouter(AsyncNotFoundContainer());
