import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SearchBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			hasFocus: false
		};
	}
	handleChange = event => {
		this.setState({ value: event.target.value });
	};

	handleKeyPress = e => {
		if (e.keyCode === 13 || e.which === 13) {
			this.handleSearch();
		}
	};

	handleKeyDown = e => {
		if (e.keyCode === 27) {
			this.handleClear();
		}
	};

	handleSearch = () => {
		this.props.onSearch(this.state.value);
	};

	handleClear = () => {
		this.setState({ value: '' });
		this.props.onSearch('');
	};

	handleFocus = () => {
		this.setState({ hasFocus: true });
	};

	handleBlur = () => {
		this.setState({ hasFocus: false });
	};

	render() {
		return (
			<div className='input-group'>
				<input
					placeholder='Search for new and used hardware'
					value={this.state.value}
					onChange={this.handleChange}
					onKeyPress={this.handleKeyPress}
					onKeyDown={this.handleKeyDown}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					type='text'
					className='form-control'
					val={this.state.value}
				/>
				<div className='input-group-append'>
					<button
						className='btn btn-primary'
						type='button'
						onClick={this.handleSearch}>
						<FontAwesomeIcon icon={['fa', 'search']} />
					</button>
				</div>
			</div>
		);
	}
}
export default SearchBox;
