import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import Loading from '../../../theme/components/loading';

const AsyncEmailVerifyContainer = () =>
  loadable(() =>
    import(
      /* webpackChunkName: "product" */ '../../../theme/containers/verifyEmail'
    ), {
    fallback: (() => <Loading />)(),
  });
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsyncEmailVerifyContainer())
);
