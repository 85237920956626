import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
// import { NavLink } from 'react-router-dom';
import apiService from '../../../store/shared/services/apiService';
import { API_ENDPOINT_PASSWORD_RESET } from '../../../store/shared/services/apiEndpoints';

class ResetPassword extends React.Component {
	// requiredFieldValidat(value) {
	//   let error;
	//   if (!value.password) {
	//     error = 'Please enter new password';
	//   } else if (!value.password_confirmation) {
	//     error = 'Please Re-enter correct password';
	//   }
	//   return error;
	// }
	validateEmail(value) {
		let error;
		if (!value) {
			error = ' Registered email is a required field';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = 'Please enter a valid registered email address';
		}
		return error;
	}

	passwordStrengthCheck(value) {
		let error;
		if (value.length < 6) {
			error = 'Password should be of 6 or more characters';
		}
		return error;
	}

	render() {
		return (
			<div className='container mt-5 mb-5'>
				<div className='row passwordResetForm'>
					<div className='col'>
						<div className='card m-auto'>
							<div className='card-body '>
								<h5 className='card-title col'>Welcome Back!</h5>
								<div className='card-text'>
									<Formik
										initialValues={{
											email: '',
											password: '',
											password_confirmation: '',
										}}
										onSubmit={(values) => {
											apiService
												.request('POST', API_ENDPOINT_PASSWORD_RESET, {
													...values,
													token: this.props.match.params.token,
												})
												.then((resp) => {
													console.log(resp);

													this.props.history.push('/login-register');
												})
												.catch((err) => {
													console.log(err);
												});
										}}>
										{({ errors, touched, isValid }) => (
											<Form method='post'>
												<div className='form-group'>
													<label>
														<b>Registered Email Address</b>
													</label>
													<Field
														className='form-control'
														name='email'
														id='email'
														validate={this.validateEmail}
													/>
													{errors.email && touched.email && <div>{errors.email}</div>}
												</div>

												<div className='form-group'>
													<label>
														<b>New Password</b>
													</label>
													<Field
														className='form-control'
														name='password'
														type='password'
														validate={this.passwordStrengthCheck}
													/>
													{errors.password && touched.password && (
														<div>{errors.password}</div>
													)}
												</div>

												<div className='form-group'>
													<label>
														<b>Re-Enter Password</b>
													</label>
													<Field
														className='form-control'
														name='password_confirmation'
														type='password'
														validate={this.passwordStrengthCheck}
													/>
													{errors.password_confirmation && touched.password_confirmation && (
														<div>{errors.password_confirmation}</div>
													)}
												</div>

												<button
													type='submit'
													className='btn btn-outline-primary mr-2'
													disabled={!isValid}>
													Reset Password
												</button>
											</Form>
										)}
									</Formik>
								</div>

								<div className='card-text' />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
