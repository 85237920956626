import { CategoryDetails } from './categories';
export class Product {
	name = '';
	slug = '';
	category_id;
	stock_quantity;
	enabled;
	discontinued;
	product_attributes = [];
	regular_price;
	on_sale;
	variable;
	price;
	retail_price;
	stock_status;
	url;
	path;
	id;
	_id;
	list_price;
	favoriters_count;
	created_at;
	updated_at;
	price_retail;
	expired_at;
	days_before_expires;
	interested_count;
	gtin;
	gmc_shipping_cost;
	quantity;
	description;
	images = [];
	shipping_details = new ShippingDetails();
	favorite;
}
export class ProductAttribute {
	id;
	name;
	slug;
	value;
}
export class ProductImages {
	_id;
	image_url;
}
export class ShippingDetails {
	weight;
	//weight_oz?: number;
	length;
	width;
	height;
}

/*export class Product {
  name: string = "";
  slug: string = "";
  category_id?: any;
  stock_quantity?: number = 0;
  enabled?: boolean = true;
  discontinued?: boolean = false;
  product_attributes?: ProductAttribute[] = [];
  regular_price?: number;
  on_sale?: boolean;
  variable?: boolean;
  price?: number;
  list_price?: number;
  favoriters_count?: number;
  favorite?: false;
  created_at?: string;
  updated_at?: string;
  price_retail?: any;
  stock_status?: 'string';
  url?: string;
  path?: string;
  _id?: any;
  state?: string;
  description?: string;
  images?: ProductImages[] = [];
  shipping_details?: ShippingDetails = new ShippingDetails();
  expired_at?: any;
  days_before_expires?: number;
  interested_count?: number;
  gmc_shipping_cost?: any;
  gtin?: any;
  quantity?: number;
  categories?: CategoryDetails[] = [];
}
export class ProductAttribute {
  name?: string;
  slug?: string;
  value?: string;
}
export class ProductImages {
  id?: any;
  image_url?: string;
}
export class ShippingDetails {
  weight?: number;
  weight_oz?: number;
  length?: number;
  width?: number;
  height?: number;
}
*/
