import React from 'react';
class Toast extends React.Component {
  componentDidMount = () => {
    setTimeout(this.props.removeToast, 3500);
  };
  render() {
    const { toast, removeToast } = this.props;
    return (
      <div
        className={`toast fade show ` + toast.type}
        role='alert'
        aria-live='assertive'
        aria-atomic='true'
      >
        <div className='toast-header'>
          <strong className='mr-auto'>{toast.type === 'error' ? 'ERROR' : 'Success'}</strong>
          <button
            type='button'
            className='ml-2 mb-1 close'
            data-dismiss='toast'
            aria-label='Close'
            onClick={removeToast}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='toast-body'>{toast.message}</div>
      </div>
    );
  }
}

export default Toast;
