import React from 'react';
import { NavLink } from 'react-router-dom';
import { text } from './settings';
import { swapListPageUrl } from '../../store/shared/services/linkCreatorService';
import copyExif from './copyExif';
import { CLEAR_SALES_SESSION_ID } from '../../store/shared/lib/constants';

export const formatValue = (val) => {
  const value = val;
  if (val === undefined) return val;
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  }
  return value;
};

export const formatNumber = (number, settings) => {
  const x = 3;
  const floatNumber = parseFloat(number || 0) || 0;

  const re = `\\d(?=(\\d{${x}})+${settings.decimal_number > 0 ? '\\D' : '$'})`;

  const num = floatNumber.toFixed(
    Math.max(0, Math.floor(settings.decimal_number))
  );

  return (settings.decimal_separator
    ? num.replace('.', settings.decimal_separator)
    : num
  ).replace(new RegExp(re, 'g'), `$&${settings.thousand_separator}`);
};
export const formatDate = (isoDate, withTime = false) => {
  const date = new Date(Date.parse(isoDate));
  return `${date.toLocaleString('en-us', {
    month: 'short',
  })} ${date.getDate()}${
    withTime ? `, ${date.getHours()}:${date.getMinutes()}` : ''
  }`;
};
export const formatTime = (isoDate) => {
  const date = new Date(Date.parse(isoDate));
  return `${date.getHours()}:${date.getMinutes()}`;
};
const amountPattern = '{amount}';
export const formatCurrency = (number = 0, settings) =>
  settings.currency_format.replace(
    amountPattern,
    formatNumber(number, settings)
  );

export const getThumbnailUrl = (originalUrl, width) => {
  if (originalUrl && originalUrl.length > 0) {
    const pos = originalUrl.lastIndexOf('/');
    const thumbnailUrl = `${originalUrl.substring(
      0,
      pos
    )}/${width}/${originalUrl.substring(pos + 1)}`;
    return thumbnailUrl;
  }
  return '';
};

export const getParentIds = (categories, id) => {
  let categoryId = id;
  const parentIds = [];
  let parentExists = false;

  do {
    const category = categories.find((item) => item.id === categoryId);
    parentExists = category && category.parent_id;
    if (parentExists) {
      parentIds.push(category.parent_id);
      categoryId = category.parent_id;
    }
  } while (parentExists);

  return parentIds;
};

export const getProductBreadcrumbs = (product, categories) => {
  if (product && product.category_id) {
    const ids = [product.category_id];
    const parentIds = getParentIds(categories, product.category_id);
    ids.push(...parentIds);

    let index = 0;
    const breadcrumbs = ids
      .reverse()
      .map((categoryId) => {
        const category = categories.find((item) => item.id === categoryId);
        if (category) {
          index += 1;
          return (
            <li key={index}>
              <NavLink to={category.path}>{category.name}</NavLink>
            </li>
          );
        }
        return null;
      })
      .filter((item) => !!item);

    return breadcrumbs;
  }
  return null;
};

export const getCategoryBreadcrumbs = (currentCategoryId, categories) => {
  if (currentCategoryId) {
    const ids = getParentIds(categories, currentCategoryId);

    let index = 0;
    const breadcrumbs = ids
      .reverse()
      .map((categoryId) => {
        const category = categories.find((item) => item.id === categoryId);
        if (category) {
          index += 1;
          return (
            <li key={index}>
              <NavLink to={category.path}>{category.name}</NavLink>
            </li>
          );
        }
        return null;
      })
      .filter((item) => !!item);

    return breadcrumbs;
  }
  return null;
};

export const getShippingMethodFromOrder = (order, shippingMethods) => {
  if (
    order &&
    order.shipping_method_id &&
    shippingMethods &&
    shippingMethods.length > 0
  ) {
    return shippingMethods.find(
      (method) => method.id === order.shipping_method_id
    );
  }
  return null;
};

export const getFieldLabelByKey = (key) => {
  switch (key) {
    case 'full_name':
      return text.fullName;
    case 'address1':
      return text.address1;
    case 'address2':
      return text.address2;
    case 'postal_code':
      return text.postal_code;
    case 'phone':
      return text.phone;
    case 'company':
      return text.company;
    case 'mobile':
      return text.mobile;
    case 'city':
      return text.city;
    case 'comments':
      return text.comments;
    default:
      return '';
  }
};

export const getShippingFieldLabel = ({ label, key }) =>
  label && label.length > 0 ? label : getFieldLabelByKey(key);

export const getCheckoutFieldLabel = ({ label, name }) =>
  label && label.length > 0 ? label : getFieldLabelByKey(name);
export const getSwapsListNavigation = () => [
  {
    type: 'buy',
    label: 'Buying',
    link: swapListPageUrl('buy'),
    links: [
      { label: 'Negotiations', link: swapListPageUrl('buy', 'negotiations') },
      { label: 'Purchased', link: swapListPageUrl('buy', 'purchased') },
      { label: 'Archived', link: swapListPageUrl('buy', 'archived') },
    ],
  },
  {
    type: 'sell',
    label: 'Selling',
    link: swapListPageUrl('sell'),
    links: [
      {
        label: 'Negotiations',
        link: swapListPageUrl('sell', 'negotiations'),
      },
      { label: 'Ship Now', link: swapListPageUrl('sell', 'pending_label') },
      {
        label: 'Pending Shipment',
        link: swapListPageUrl('sell', 'pending_shipment'),
      },
      { label: 'Shipped', link: swapListPageUrl('sell', 'shipped') },
      { label: 'Completed', link: swapListPageUrl('sell', 'completed') },
      { label: 'Archived', link: swapListPageUrl('sell', 'archived') },
    ],
  },
];
export const getSettingsNavigation = (username) => [
  { label: 'My Setup', link: `/account/${username}` },
  { label: 'Seller Dashboard', link: '/seller' },
  { label: 'Purchases', link: '/purchases' },
  { label: 'Settings', link: '/settings/account' },
  // { label: 'My Notifications', link: '/settings/notifications' },
  { label: 'Addresses', link: '/settings/addresses' },
  { label: 'Payment Methods', link: '/settings/payment-methods' },
];
export const getUserAccountNavigation = (username) => [
  { label: 'Items', link: `/account/${username}/items` },
  { label: 'Feedback', link: `/account/${username}/feedback` },
  { label: 'Favorites', link: `/account/${username}/favorites` },
  // {label: 'Followers', link:`/account/${username}/followers`},
  // {label: 'Following', link:`/account/${username}/following`},
];
export const tabObject = (label, ownerAccessRequired, params = {}) => ({
  label,
  ownerAccessRequired,
  params: { ...params },
});
export const showRenewButton = (item) => {
  if (item.expired_at) {
    const daysToExpiry = parseInt(
      (new Date(item.expired_at) - new Date()) / (1000 * 60 * 60 * 24)
    );
    if (daysToExpiry <= 10) {
      return true;
    }
  }
  return false;
};
export const loadZopimChat = (callback) => {
  const existingScript = document.getElementById('ze-snippet');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=6005c18f-6445-4d47-aec0-427c42f82918';
    script.id = 'ze-snippet';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) {
        window.zE(() => {
          callback();
        });
      }
    };
  }
};
export const unLoadZopimChat = () => {
  const existingScript = document.getElementById('ze-snippet');
  if (existingScript) {
    existingScript.remove();
  }
};
export const loadFreshChat = (callback) => {
  const existingScript = document.getElementById('fc-snippet');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://wchat.freshchat.com/js/widget.js';
    script.id = 'fc-snippet';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback && window.fcWidget) {
        callback();
      }
    };
  }
};
export const generateClearsaleSession = () => {
  let csSession = document.getElementById(CLEAR_SALES_SESSION_ID);
  if (!csSession) {
    csSession = document.createElement('input');
    csSession.type = 'hidden';
    csSession.id = CLEAR_SALES_SESSION_ID;
    csSession.value = '';
    document.getElementById('main').appendChild(csSession);
  }
};
// function to handle null values in buyer, seller and item
export const destructSwap = (swap) => ({
  ...swap,
  buyer: swap.buyer || {},
  seller: swap.seller || {},
  item: swap.item || {},
});
export const getItemAttributeValue = (attributes, attributeSlug) => {
  if (!attributes) {
    return null;
  }
  const attribute = attributes.find(
    (attribute) => attribute.slug === attributeSlug
  );
  return attribute && attribute.value;
};
export const compressImage = (
  file,
  quality = 50,
  max_width = 600,
  max_height = 600
) =>
  new Promise(async (resolve) => {
    const fileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = function () {
        const { width } = image;
        const { height } = image;

        const canvas = document.createElement('canvas');

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        ctx.canvas.toBlob(
          async (blob) => {
            const compressedFile = new File(
              [await copyExif(file, blob)],
              fileName,
              {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }
            );
            resolve(compressedFile);
          },
          'image/jpeg',

          //     (fileName &&
          //       `${fileName.substring(0, fileName.lastIndexOf('.'))}.webp`) ||
          //       fileName,
          //     {
          //       type: 'image/webp',
          //       lastModified: Date.now(),
          //     }
          //   );
          //   resolve(compressedFile);
          // },
          // 'image/webp',
          quality / 100
        );
      };
    };
  });
export function getOrientation(file, callback) {
  const reader = new FileReader();
  reader.onload = function (e) {
    const view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xffd8) {
      return callback(-2);
    }
    const length = view.byteLength;
    let offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          return callback(-1);
        }

        const little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little));
          }
        }
      } else if ((marker & 0xff00) != 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}
export const fbFireTrackEvent = (item, event = 'ViewContent') => {
  if (window.fbq) {
    window.fbq('track', event, {
      value: item.price,
      currency: 'USD',
      contents: [
        {
          id: item._id,
          quantity: item.stock_quantity,
        },
      ],
      content_type: 'product',
    });
  }
};

export const addGoogleRecaptchaScript = () => {
  const existingScript = document.getElementById('google-recaptcha');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.id = 'google-recaptcha';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // script.onload = () => {
    // 	if (callback && window.fcWidget) {
    // 		callback();
    // 	}
    // };
  }
};

export const categoryTitleHelper = (name) => {
  const categories = name.split('>');
  const category = categories[categories.length - 1].trim();
  const selectedCategory =
    category[category.length - 1] === 's' &&
    category !== 'Miscellaneous' &&
    category !== 'Other Accessories' &&
    category !== 'Power Supplies'
      ? category.slice(0, -1)
      : category;
  const improvedSelectedCategory = selectedCategory.endsWith('ies')
    ? selectedCategory.replace(/ies/, 'y')
    : selectedCategory;
  return improvedSelectedCategory;
};

export const attributesTitleSuggestionHelper = (attributes, category) => {
  let title = '';
  let titleBrand = '';
  let titleModel = '';
  let titleCondition = '';
  attributes.forEach((el) => {
    if (el.name.toLowerCase() === 'brand') {
      titleBrand = el.value;
    }
    if (el.name.toLowerCase() === 'model') {
      titleModel = el.value;
    }
    if (el.name.toLowerCase() === 'condition') {
      titleCondition = el.value;
    }
  });
  if (titleCondition) {
    title = `${titleCondition} ${category}`;
  }
  if (titleBrand) {
    title = `${titleCondition} ${titleBrand} ${category}`;
  }
  if (titleModel) {
    title = `${titleCondition} ${titleModel} ${category}`;
  }
  if (titleBrand && titleModel) {
    title = `${titleCondition} ${titleBrand} ${titleModel} ${category}`;
  }
  return title;
};
