import React from 'react';
import AppPortal from '../components/appPortal';
import Modal from '../components/Modal';

export class Modals extends React.Component {
  componentDidMount() {
    this.props.history.listen((location, action) => {
      // location is an object like window.location
      this.props.closeAllModals();
    });
  }

  closeAllModals = (event) => {
    const { target } = event;
    if (target.classList.contains('modal')) {
      this.props.closeAllModals();
    }
  };

  render() {
    const { modals } = this.props;
    return Array.isArray(modals) && modals.length ? (
      <AppPortal>
        <div id="modals" onClick={this.closeAllModals}>
          {modals.map((item, i) => (
            <Modal
              key={i}
              item={item}
              onClose={(item) => this.props.closeModal(item)}
            />
          ))}
        </div>
      </AppPortal>
    ) : null;

    // return "<div className={modals.length > 0 ? 'modals' : ''}>{modals}</div>";
  }
}
