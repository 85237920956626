import React from 'react';
import Amex from '../assets/svgs/amex.svg';
import Discover from '../assets/svgs/discover.svg';
import Mastercard from '../assets/svgs/mastercard.svg';
import Visa from '../assets/svgs/visa.svg';

function PaymentMethodsInfo() {
  return (
    <div className="mx-2">
      <img src={Amex} alt="American Express" className="" />

      <img src={Discover} alt="American Express" className="" />
      <img src={Visa} alt="American Express" className="" />
      <img src={Mastercard} alt="American Express" className="" />
    </div>
  );
}

export default PaymentMethodsInfo;
