import React from 'react';
import { NavLink } from 'react-router-dom';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import logo from '../../assets/images/pcswa_logo.png';
import SearchBox from './searchBox';
import HeadTopMenu from './headTopMenu';
import HeadMenu from './headMenu';
import apiService from '../../../store/shared/services/apiService';
import { API_ENDPOINT_CATEGORIES } from '../../../store/shared/services/apiEndpoints';
import '../../assets/scss/header.scss';
import MobileHeadMenu from './mobileHeadMenu';
import { isServer } from '../../../store/shared/redux/store';

const BurgerButton = ({ onClick, className, isActive }) => (
  <span className={`${className} d-lg-none`} onClick={onClick}>
    <FontAwesomeIcon icon={isActive ? faTimes : faBars} size="2x" />
  </span>
);
class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    let categories = [];
    let user = null;
    let tokens = null;
    if (!isServer) {
      categories = window.__PRELOADED_CATEGORIES__
        ? window.__PRELOADED_CATEGORIES__.categories
        : [];
      user = window.__PRELOADED_CATEGORIES__
        ? window.__PRELOADED_CATEGORIES__.user
        : null;
      tokens = window.__PRELOADED_CATEGORIES__
        ? window.__PRELOADED_CATEGORIES__.tokens
        : null;
      if (
        !(Object.keys(tokens).length === 0 && tokens.constructor === Object)
      ) {
        props.setTokens({ ...tokens });
      }
      if (!(Object.keys(user).length === 0 && user.constructor === Object)) {
        props.setUser({ ...user });
      }
    } else if (props.staticContext && props.staticContext.categoriesData) {
      categories = props.staticContext.categoriesData.categories;
    }

    this.state = {
      categories,
      mobileMenuIsActive: false,
    };
  }

  fetchCategories() {
    apiService.request('GET', API_ENDPOINT_CATEGORIES).then(
      (resp) => {
        if (resp.data.categories) {
          this.setState({ categories: [...resp.data.categories] });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  componentDidMount() {
    /* if (!isServer) {
				this.props.getUserFromSession();
			}
			*/
    if (!this.state.categories || this.state.categories.length === 0) {
      this.fetchCategories();
    }
  }

  handleSearch = (search) => {
    this.props.history.push(`/search?search=${search}`);
  };

  menuToggle = () => {
    this.setState({
      mobileMenuIsActive: !this.state.mobileMenuIsActive,
    });
    document.body.classList.toggle('noscroll');
  };

  menuClose = () => {
    this.setState({ mobileMenuIsActive: false });
    document.body.classList.remove('noscroll');
  };

  render() {
    const { categories, mobileMenuIsActive } = this.state;
    const classToggle = this.state.mobileMenuIsActive
      ? 'navbar-burger is-hidden-tablet is-active'
      : 'navbar-burger is-hidden-tablet';
    return (
      <div className="header">
        <header className="position-relative">
          <BurgerButton
            className={classToggle}
            isActive={mobileMenuIsActive}
            onClick={this.menuToggle}
          />

          <div className="top-header pt-2 pb-2">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xs-2 col-sm-12 col-md-12 col-lg-2 mb-2">
                  <NavLink className="logo-image" to="/">
                    <img className="img-fluid" src={logo} alt="Swap website" />
                  </NavLink>
                </div>
                <div className="col-md-12 col-lg-6 mb-2 searchBar-append-search-icon ">
                  <SearchBox onSearch={this.handleSearch} />
                </div>
                <div className="col-md-12 col-lg-4 mb-2 text-center align-content-center">
                  <HeadTopMenu isMobile={false} history={this.props.history} />
                </div>
              </div>
            </div>
          </div>

          <nav className="navbar navbar-expand-lg navbar-light d-none  d-lg-block p-0">
            <HeadMenu
              isMobile={false}
              categories={categories}
              onClick={this.menuClose}
            />
          </nav>
        </header>
        <MobileHeadMenu
          mobileMenuIsActive={mobileMenuIsActive}
          isMobile
          data={categories}
          onClick={this.menuClose}
        />
        {/* </div> */}
      </div>
    );
  }
}
export default withRouter(Header);
