import React from 'react';
import { toggleDarkmode } from '../../../store/shared/redux/actions/userAction';

const ToggleSwitch = () => (
  <div className="d-inline-block mx-3" onClick={toggleDarkmode}>
    <div className="toggler">
      <div className="d-inline-block">☀️</div>
      <div className="d-inline-block">🌙</div>
    </div>
  </div>
);

export default ToggleSwitch;
