import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from '../../../theme/components/MetaTags';
import img1 from '../../../theme/assets/images/howitworks/1.png';
import img2 from '../../../theme/assets/images/howitworks/2.png';
import img3 from '../../../theme/assets/images/howitworks/3.png';
import img4 from '../../../theme/assets/images/howitworks/4.png';
import img5 from '../../../theme/assets/images/howitworks/5.png';
import img6 from '../../../theme/assets/images/howitworks/6.png';
import img7 from '../../../theme/assets/images/howitworks/7.png';
import img8 from '../../../theme/assets/images/howitworks/8.png';
import img9 from '../../../theme/assets/images/howitworks/9.png';

const HowItWorks = () => {
  const [selling, setSelling] = useState(true);
  return (
    <div className="container pt-3">
      <MetaTags
        title="How It Works - PC Swaps"
        ogTitle="How It Works - PC Swaps"
        ogDescription="Our selling fee is 8% TOTAL. No hidden fees or extra costs.
                  Once the buyer accepts the item as advertised or 3 days have
                  after delivery you will be able to cash out securely via Venmo
                  or Paypal."
        description="Our selling fee is 8% TOTAL. No hidden fees or extra costs.
                  Once the buyer accepts the item as advertised or 3 days have
                  after delivery you will be able to cash out securely via Venmo
                  or Paypal."
      />
      <h3 className="display-5 text-center mb-3 font-weight-bold">
        How It Works
      </h3>

      <ul className="nav nav-tabs mb-3">
        <li className="nav-item">
          <button
            type="button"
            onClick={() => setSelling(false)}
            className={`nav-link ${selling ? 'inactive' : 'active'}`}
          >
            Buying
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            onClick={() => setSelling(true)}
            className={`nav-link ${selling ? 'active' : 'inactive'}`}
          >
            Selling
          </button>
        </li>
      </ul>

      {selling ? (
        <>
          <h4 className="display-5 text-center mb-3 font-weight-bold">
            Selling on PC Swaps
          </h4>
          <div className="col justify-content-around">
            <div className="row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img1} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3  font-weight-bolder">
                  Create your account
                </h5>
                <p className="d-inline">
                  After you{' '}
                  <Link
                    to={{
                      pathname: 'login-register',
                      state: { activeTab: 'register' },
                    }}
                  >
                    create your seller account
                  </Link>{' '}
                  you can begin to list items for free.
                </p>
              </div>
            </div>
            <div className="row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img2} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3 font-weight-bolder">List in seconds</h5>
                <p>
                  It's as simple as taking a few pictures, selecting attributes,
                  and naming a price to start making sales.
                </p>
              </div>
            </div>
            <div className=" row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img3} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3  font-weight-bolder">Simple Shipping</h5>
                <p>
                  You'll be notified once your items sells and you'll simply
                  need to generate and print the prepaid label we provide and
                  get your item shipped.
                </p>
              </div>
            </div>
            <div className=" row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img4} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3  font-weight-bolder">
                  Fast payments and low fees
                </h5>
                <p>
                  Our selling fee is 8% TOTAL. No hidden fees or extra costs.
                  Once the buyer accepts the item as advertised or 3 days have
                  after delivery you will be able to cash out securely via Venmo
                  or Paypal.
                </p>
              </div>
            </div>
            <div className=" row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img5} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3  font-weight-bolder">Earn feedback</h5>
                <p>
                  Grow your reputation through positive reviews from buyers and
                  earn trust instantly from future customers. Quick shipping and
                  good communication are key to happy customers!
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4 className="display-5 text-center mb-3 font-weight-bold">
            Buying on PC Swaps
          </h4>
          <div className="col justify-content-around">
            <div className="row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img6} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3 font-weight-bolder">Shop the best deals</h5>
                <p>
                  Build your dream rig at your dream price. Find parts up to 70%
                  off retail prices on PC Swaps!
                </p>
              </div>
            </div>
            <div className="row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img7} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3  font-weight-bolder">
                  Buy now or make an offer
                </h5>
                <p>
                  Purchase the item at the list price or make an offer to the
                  seller. When making an offer you will not be charged until the
                  seller accepts.
                </p>
              </div>
            </div>
            <div className="row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img8} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3 font-weight-bolder">Buyer protection</h5>
                <p>
                  <a
                    href="https://blog.pcswaps.com/what-is-buyer-protection"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PC Swaps Buyer Protection
                  </a>{' '}
                  covers any item that is damaged or not as advertised in the
                  listing. If the item is not as advertised open a dispute and
                  our world class support team will help you.
                </p>
              </div>
            </div>
            <div className="row py-3 text-center justify-content-center">
              <div className="col-lg-3 col-12">
                <img src={img9} alt="" height={200} className="icon-grow" />
              </div>
              <div className="col-lg-5 col-12">
                <h5 className="mt-3  font-weight-bolder">Accept your item</h5>
                <p>
                  Once you have received your item as advertised you should
                  accept the item so the seller can receive their payment. Also
                  leave a review to help other buyers!
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HowItWorks;
