import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';

const HeadMenuItem = ({ category, level }) => {
  const childrenExists = category.children && category.children.length > 0;
  return (
    <li className={childrenExists ? 'nav-item  dropdown' : 'nav-item'}>
      <NavLink
        className={childrenExists ? 'nav-link dropdown-toggle ' : 'nav-link'}
        to={'/products/' + category._id}
      >
        {category.name}
      </NavLink>
      {childrenExists ? (
        <ul
          className={
            level <= 1
              ? 'dropdown-menu headerList '
              : 'dropdown-submenu headerList '
          }
        >
          {category.children.map(childCategory => (
            <HeadMenuItem
              key={childCategory._id}
              category={childCategory}
              level={level + 1}
            />
          ))}
        </ul>
      ) : (
        ''
      )}
    </li>
  );
};

const HeadMenu =(props) => {
  const { categories } = props;
  const [menuLength, setMenuLength]=useState(categories.length)

  useEffect(()=>{
    if (typeof window !== 'undefined') {
      
      // Add event listener
      window.addEventListener("resize", handleResize);
     
      // Call handler right away so state gets updated with initial window size
      handleResize();
    
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }

    function handleResize() {
      setMenuLength(Math.floor(window.innerWidth/120))
    }

  })
  

    const items = categories
      .slice(0, menuLength)
      .map((category, index) => (
        <HeadMenuItem key={index} category={category} level={1}/>
      ));
    const misc = categories
      .slice(menuLength, categories.length)
      .map((category, index) => (
        <HeadMenuItem key={index} category={category} level={2} />
      ));
    return (
      <Fragment>
        <div
          className='collapse navbar-collapse container text-nowrap justify-content-around'  
        >
          <ul className='navbar-nav headerList parentCatList'>
            {items}
            {categories.length > menuLength && (
              <li className='nav-item dropdown'>
                <button
                  type='btn'
                  className={
                    'nav-link dropdown-toggle btn btn-link btn-small'
                  }
                >
                  Show More
                </button>
                <ul className='dropdown-menu'>{misc}</ul>
              </li>
            )}
          </ul>
        </div>
      </Fragment>
    );
  }


export default HeadMenu;
