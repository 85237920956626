import React, { Fragment } from "react";
import PageTitleContainer from "../../../theme/components/header/pageTitleContainer";

const Sitemap = (props) => {
  return (
    <Fragment>
      <PageTitleContainer title="Sitemap" />
      <div className="container">
        <div className="row parentCatList">
          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Navigation</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/">Home page</a>
            </li>
            <li>
              <a href="https://www.pcswaps.com/seller">Sell On PC Swaps</a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/purchases">Purchases</a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/settings/account">Settings</a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/items/new">List an Item</a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/login-register">Login</a>
            </li>
            <li>
              <a href="https://blog.pcswaps.com">Help Center and FAQ</a>
            </li>
            <li>
              <a href="https://www.pcswaps.com/login-register">Register</a>
            </li>
          </ul>
          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Legal</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/terms-of-use">Terms</a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/privacy-policy">
                Privacy Policy
              </a>
            </li>
          </ul>
          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>CPU Coolers</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a7c54b6ab03d2c186325">
                CPU Coolers
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f0304b6ab053b84bdc35">
                Air Coolers
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f04b4b6ab0667f0781e3">
                Liquid Coolers
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Accessories</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a91b4b6ab03d2c186327">
                Case Fans and Accessories
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d2bc1a74b6ab01c0b6b4b02">
                Case Fans
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d2bc1cb4b6ab009066e9df2">
                Other Accessories
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Cases</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a8944b6ab053e742be27">
                Cases
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Graphics Cards</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d279dff4b6ab03d2b6109a3">
                Graphics Cards
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Laptops</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d7998f3e7b1fd42c337a593">
                Laptops
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d7ebee6e7b1fd655441d422">
                2-in-1 Laptops
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d7ebdcbe7b1fd65522eb6f2">
                Gaming Laptops
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d7ebe65e7b1fd655000fab2">
                Laptops/Notebooks
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Miscellaneous</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d2bc3554b6ab007c06403e2">
                Miscellaneous
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Monitors</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a74a4b6ab053e742be25">
                Monitors
              </a>
            </li>
          </ul>
          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Motherboards</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a8f14b6ab00b8831d643">
                Motherboards
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d2899ed4b6ab053b84bdc32">
                AMD Motherboards
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d289a0e4b6ab031b3284292">
                Intel Motherboards
              </a>
            </li>
          </ul>
          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>PCs</h5>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d27a7344b6ab03d2d756375">
                PCs
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Peripherals</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27dee14b6ab00b8831d645">
                Peripherals
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d2e2fef4b6ab0555a273fe2">
                Headphones
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d27a87f4b6ab049a65796e4">
                Keyboards
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d27a8664b6ab00b6b3e8016">
                Mice
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Power Supplies</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a7244b6ab00b6a6f8e93">
                Power Supplies
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Processors</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a6944b6ab03d2d756373">
                Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d289b044b6ab031b203ff92">
                AMD Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28dca44b6ab031af04ec53">
                AMD FX processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28dd654b6ab031b3284295">
                Other AMD processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28d9fc4b6ab0667f0781e2">
                Ryzen Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d289b1e4b6ab031b3284293">
                Intel Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28a5504b6ab031b01a0832">
                Intel i3 Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28a56e4b6ab053b84bdc34">
                Intel i5 Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28a5824b6ab031b3284294">
                Intel i7 Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28a5934b6ab031af04ec52">
                Intel i9 Processors
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28a5af4b6ab031b01a0833">
                Other Intel Processors
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>RAM</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a6b34b6ab053e742be23">
                RAM
              </a>
            </li>
          </ul>

          <ul className="col-6 col-sm-4 col-md-3 footerList">
            <li>
              <h5>Storage</h5>
            </li>
            <li>
              <a href="https://www.pcswaps.com/products/5d27a6ed4b6ab03d2b6109a5">
                Storage
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f3634b6ab031b203ff93">
                External Storage
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f3e74b6ab031b01a0835">
                External Hard Drives
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f3d44b6ab031af04ec54">
                External Solid State Drives
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f3474b6ab031b01a0834">
                Internal Storage
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f3a04b6ab031b3284296">
                Internal Hard Drives
              </a>
            </li>

            <li>
              <a href="https://www.pcswaps.com/products/5d28f37b4b6ab066830e0a22">
                Internal Solid State Drives
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};
export default Sitemap;
