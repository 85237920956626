import React from 'react';
import Toast from '../components/toasts/toast';
import AppPortal from '../components/appPortal';
import '../assets/scss/toasts.scss';
const Toasts = ({ toasts, removeToast }) => {
  return toasts.length > 0 ? (
    <AppPortal>
      <div className='toast-stack' aria-live='polite' aria-atomic='true'>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          {toasts.map(toast => {
            const { id } = toast;
            return <Toast toast={toast} key={id} removeToast={() => removeToast(id)} />;
          })}{' '}
        </div>
      </div>
    </AppPortal>
  ) : (
    ''
  );
  //return toastsDiv;
  //return <div className={toastsDiv.length > 0 ? 'toasts' : ''}>{toastsDiv}</div>;
};
export default Toasts;
