// import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { removeToast } from '../redux/actions/toastsAction';
import Toasts from '../../../theme/containers/toasts';
//import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
// const AysncModalContainer = () =>
//   Loadabel({
//     loader: () => import('../../../theme/containers/modal'),
//     loading: () => <h2>Loading....</h2>
//   });
const mapStateToProps = state => {
  return {
    toasts: state.toasts
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { removeToast }
  )(Toasts)
);
