// import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
//import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import { Modals } from '../../../theme/containers/modals';
import { closeModal, closeAllModals } from '../redux/actions/modalAction';
// const AysncModalContainer = () =>
//   Loadabel({
//     loader: () => import('../../../theme/containers/modal'),
//     loading: () => <h2>Loading....</h2>
//   });
const mapStateToProps = state => {
  return {
    state: state.app,
    modals: state.modals,
    toasts: state.toasts
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { closeModal, closeAllModals }
  )(Modals)
);
