/* eslint-disable react/button-has-type */
import { Field, Form, Formik, getIn } from 'formik';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// import { GoogleLogin } from 'react-google-login';
// import FacebookAuth from 'react-facebook-auth';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { registerUser } from '../../../store/shared/redux/actions/userAction';
// import "../../assets/scss/index.scss";
import { addGoogleRecaptchaScript } from '../../lib/helper';

// const Register = props =>
const referenceOptions = [
  'Instagram',
  'Facebook',
  'Youtube',
  'Discord',
  'Google Search',
  'Reddit',
  'Craigslist',
  'Text Message',
  'Other',
];
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isOtherRef: false,
    };
  }

  componentDidMount() {
    addGoogleRecaptchaScript();
  }

  submitData = (event) => {
    event.preventDefault();

    const data = {
      // user: {
      email: this.emailID.current.value,
      username: this.userName.current.value,
      password: this.password.current.value,
      reference: this.reference.current.value,
      // }
    };

    this.props
      .registerUser(data)
      .then((resp) => {
        this.setState({ isSubmitting: true });
        this.props.onSuccess(resp.data.user);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isSubmitting: false });
      })
      .finally(this.setState({ isSubmitting: false }));
    // document.getElementById("signUpForm").reset();
  };

  validateEmail(value) {
    let error;
    if (!value) {
      error = 'Email is a required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  requiredValidator(value) {
    let error;
    if (!value) {
      error = 'This is a required field';
    }
    return error;
  }

  passwordStrengthCheck(value) {
    let error;
    if (value.length < 6) {
      error = 'Password should be of 6 or more characters';
    }
    return error;
  }

  referenceValidator(event) {
    const { value } = event.target;
    if (value === 'other') {
      this.setState({ isOtherRef: true });
    }
  }

  usernameValidator(value) {
    let error;
    // var format = /^[!@$%^&*()+\-=\[\]{};':"\\|,.<>\/?#]*$/;
    // var format = /^[!@$%^&*()+\-=\[\]{};':"\\|,.<>\/?#]*$/;
    // let specialCharacters = format.test(value);
    // console.log('consist special charactes', specialChar);
    const regex = /^[A-Za-z0-9_]+$/;
    const specialChar = regex.test(value);
    if (
      value.indexOf(' ') >= 0 ||
      !value ||
      value.length > 15 ||
      !specialChar
    ) {
      return (error =
        'Username should be a continuous alphanumeric string without any special characters and less than 15');
    }
    return error;
  }

  validateRegisterForm = (values) => {
    const errors = {};
    if (values.reference === 'other' && !values.reference_other) {
      errors.reference_other = 'Please specify a reference';
    }
    if (!values.reference) {
      errors.reference = 'Please select at least one option';
    }
    return errors;
  };

  validateCaptcha = (value) => {
    let error;
    if (!value) {
      error = 'Recaptcha verification is required';
    }
    return error;
  };

  renderReferenceField = (values, errors, touched) => (
    <>
      <Field
        name="reference"
        render={(props) => {
          const { field } = props;
          const defaultOption = (
            <option key="default" value="Please Select">
              Please Select
            </option>
          );
          const options = referenceOptions.map((value, index) => (
            <option value={value} key={index}>
              {value}
            </option>
          ));
          const selectOptions = [defaultOption, ...options];
          return (
            <div className="form-group">
              <label>
                <b>How did you find PC Swaps?</b>
              </label>
              <select className="form-control" value={field.value} {...field}>
                {selectOptions}
              </select>
              {errors.reference && touched.reference && (
                <small>{errors.reference}</small>
              )}
            </div>
          );
        }}
      />
      <Field
        name="reference_other"
        render={({ field }) =>
          // 'getIn'formik function to compare values and show input field only when "other" is selected
          getIn(values, 'reference') === 'Other' ? (
            <div className="form-group">
              <label>
                <b>Please specify</b>
              </label>
              <input className="form-control" value={field.value} {...field} />
              {errors.reference_other && touched.reference_other && (
                <small>{errors.reference_other}</small>
              )}
            </div>
          ) : null
        }
      />
    </>
  );

  render() {
    return (
      <>
        <div className="row ">
          <div className="col">
            <div className="card m-auto">
              <div className="card-body ">
                <h5 className="card-title col">Create your PC Swaps Account</h5>
                <Formik
                  validate={this.validateRegisterForm}
                  initialValues={{
                    email: '',
                    username: '',
                    password: '',
                    reference: '',
                    reference_other: '',
                    // recaptcha: ''
                  }}
                  // validate={values=>{
                  //   is_val
                  // }}
                  onSubmit={(values) => {
                    const data = {
                      ...values,
                      email: values.email.toLocaleLowerCase(),
                      username: values.username.toLocaleLowerCase(),
                    };

                    this.props
                      .registerUser(data)
                      .then((resp) => {
                        this.props.onSuccess(resp.data);
                        // console.log(resp.data);
                      })
                      .catch((err) => {
                        console.log(err);
                        // document.registerForm.reset();
                      })
                      .finally(() => {
                        // document.registerForm.reset();
                      });
                  }}
                >
                  {({ errors, touched, isValid, values, setFieldValue }) => (
                    <Form name="registerForm" method="post">
                      <div className="card-text">
                        <div className="form-group">
                          <label>
                            <b>Email Address</b>
                          </label>
                          <Field
                            className="form-control"
                            name="email"
                            validate={this.validateEmail}
                          />
                          {errors.email && touched.email && (
                            <small>{errors.email}</small>
                          )}
                        </div>

                        <div className="form-group">
                          <label>
                            <b>Username</b>
                          </label>
                          <Field
                            className="form-control"
                            name="username"
                            validate={this.usernameValidator}
                          />
                          {errors.username && touched.username && (
                            <small>{errors.username}</small>
                          )}
                        </div>

                        <div className="form-group">
                          <label>
                            <b>Password</b>
                          </label>

                          <Field
                            className="form-control"
                            name="password"
                            type="password"
                            validate={this.passwordStrengthCheck}
                          />
                          {errors.password && touched.password && (
                            <div>{errors.password}</div>
                          )}
                        </div>

                        {this.renderReferenceField(values, errors, touched)}

                        {/* <div className='form-group'>
													<Field
														name='recaptcha'
														validate={this.validateCaptcha}
														render={() => (
															<>
																<Recaptcha
																	sitekey='6LclSs8UAAAAACymD-TssWu_0D5hBtlg4Xuu4j1'
																	render='explicit'
																	verifyCallback={response =>
																		setFieldValue('recaptcha', response)
																	}
																	onloadCallback={() => console.log('recaptcha loaded!')}
																/>
																{errors.recaptcha && touched.recaptcha && (
																	<div>{errors.recaptcha}</div>
																)}
															</>
														)}
													/>
												</div> */}
                        <div className="form-group float-right mt-3">
                          <Link
                            className="btn-link text-primary "
                            to="/howitworks"
                          >
                            How it Works
                          </Link>
                        </div>

                        {this.state.isSubmitting ? (
                          <label className="btn btn-outline-primary mt-2">
                            Please Wait...
                          </label>
                        ) : (
                          <div>
                            <button
                              className="btn btn-outline-primary mt-2"
                              type="submit"
                              disabled={!isValid}
                            >
                              Register
                            </button>
                            <div>
                              {/* <GoogleLogin
                                clientId="your_client_id"
                                render={() => (
                                  <button
                                    className="btn btn-outline-primary mt-2"
                                    style={{ marginRight: '8px' }}
                                  >
                                    Register with{' '}
                                    <FontAwesomeIcon icon={faGoogle} />
                                  </button>
                                )}
                                onSuccess={(response) => {
                                  const data = {
                                    email: response.profileObj.email,
                                    username: response.profileObj.email,
                                    reference: null,
                                    socialLogin: true,
                                  };

                                  // this.props
                                  //   .registerUser(data)
                                  //   .then((resp) => {
                                  //     this.props.onSuccess(resp.data);
                                  //     console.log(resp.data);
                                  //   })
                                  //   .catch((err) => {
                                  //     console.log(err);
                                  //     // document.registerForm.reset();
                                  //   })
                                  //   .finally(() => {
                                  //     // document.registerForm.reset();
                                  //   });
                                }}
                                onFailure={console.log}
                                isSignedIn
                              />
                              <FacebookAuth
                                appId="your app id"
                                component={() => (
                                  <button className="btn btn-outline-primary mt-2">
                                    Register with{' '}
                                    <FontAwesomeIcon icon={faFacebook} />
                                  </button>
                                )}
                              /> */}
                            </div>
                          </div>
                        )}

                        <div className="registerTermsAndConditions">
                          <span>
                            By clicking Register, I agree to PC Swaps's
                          </span>
                          &nbsp;
                          <NavLink to="/terms-of-use">Terms of Service</NavLink>
                          &nbsp;
                          <span>and</span> &nbsp;
                          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { registerUser })(Register);
